import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Table,
  Tag,
} from "antd";
import dayjs from "dayjs";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { ReactComponent as Filter } from "@/images/filter.svg";
import { useWindowSize } from "@/adminApp/models/model_admin_size";

import useConfirm from "@/components/Modal/Confirm";

import {
  userAccess,
  userTag,
  userDelete,
  getUserTag,
  userEditTag,
} from "../../apis/user-api";

import "./admin_donors.less";
import queryString from "query-string";
import { getEnv } from "@/libs/cfg";
import { donorAddNote, donorList } from "@/adminApp/apis/donor-api";
import { disciplineOptions } from "@/types/enum";
import { stateList } from "@/types/enum";
import { ColumnProps } from "antd/lib/table";
import { DonorReportModal } from "./report_modal"
import { admin_router_donors_report } from "@/adminApp/routers";

export const roles = [
  {
    label: "Artist",
    value: "artist",
  },
  {
    label: "Donor",
    value: "donor",
  },
  {
    label: "Admin",
    value: "administrator",
  },
];

const receivers = [
  {
    label: "Donation to The Filed",
    value: "The Filed",
  },
  {
    label: "Donation to Artist",
    value: "Artist",
  },
];
const initParam = {
  page: 1,
  limit: 10,
  keyword: "",
  note: "",
};

export const AdminDonors = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { setShow: deleteShow, component: deleteComponent } = useConfirm(
    <div className="flex column start align-start">
      <p>
        You are about to permantly delete this account. <br /> By clicking
        ‘Delete’, you will:
      </p>
      <ul>
        <li>Cancel all subscriptions </li>
        <li>Delete this account </li>
        <li>Delete all content related to this account</li>
        <li>Disable this user from loging in </li>
      </ul>
    </div>,
    {
      title: "Are you sure you want to permanently delete this account ?  ",
      className: "suspend-confirm-modal",
      okText: "Delete",
      onOk: (id?: any) => {
        userDelete({
          id,
        }).then(() => {
          getList();
          message.success("successfully");
        });
      },
    }
  );

  //handler
  const handleExport = () => {
    const { page, limit, date, ...others } = params;
    const [startDate, endDate] = date || [null, null];
    let _data = {
      startDate: startDate?.toDate(),
      endDate: endDate?.toDate(),
      ...others,
    };
    window.open(
      `${process.env.REACT_APP_API_HOST
      }/user/donor/export-csv?${queryString.stringify({
        ..._data,
        exportId: selectedRows.map((item: any) => item.id).join(","),
        tz: dayjs.tz.guess()
      })}`,
      "_blank"
    );
  };

  //run a report
  const [report, setReport] = useState(false)
  const showReport = () => {
    setReport(true)
  }
  const hideReport = () => {
    setReport(false)
  }
  const goReport = (date: any) => {
    hideReport()
    history.push({ pathname: admin_router_donors_report, state: date })
  }

  // -add tag
  const [tagForm] = Form.useForm();
  const [tagList, setTagList] = useState<any[]>([]);
  const [tagModal, setTagModal] = useState<object | boolean>(false);
  const getTags = () => {
    getUserTag().then((data) => setTagList(data));
  };
  // const handleShowTagModal = (row: any) => {
  //   setTagModal(row);
  //   if (row !== true) {
  //     const { id, tags = "" } = row;
  //     tagForm.setFieldsValue({
  //       id,
  //       tags: tags || [],
  //     });
  //   }
  // };
  const handleTagFinish = (values: any) => {
    setLoading(true);
    if (tagModal === true) {
      userTag(values)
        .then((data) => {
          message.success("Add successfully");
          tagForm.resetFields();
          setTagModal(false);
          setLoading(false);
          getTags();
        })
        .catch(() => setLoading(false));
    } else {
      userEditTag(values)
        .then(() => {
          message.success("Edit successfully");
          tagForm.resetFields();
          setTagModal(false);
          setLoading(false);
          getList();
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    getTags();
    return () => {
      if (location.state) {
        location.state = null
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // -view note
  const [noteForm] = Form.useForm();
  const [noteModal, setNoteModal] = useState<any>(undefined);
  const handleViewNote = (row: any) => {
    setNoteModal(row);
    noteForm.setFieldsValue({ ...row, tags: row.tags || undefined });
  };
  const handleNoteFinish = (values: any) => {
    donorAddNote({
      id: noteModal.id,
      ...values,
    }).then((data) => {
      setNoteModal(undefined);
      getList();
      message.success("successfully");
    });
  };
  const handleAccessToUser = (id: number, to: string) => {
    userAccess({ id }).then((data) => {
      window.open(`${getEnv("WEB_HOST")}/auth/${data.token
        }?to=${to}`, '_blank');
    });
  };

  const handleDelete = (row: any) => {
    deleteShow(row.id);
  };

  //handler end

  const column: ColumnProps<any>[] = [
    {
      title: "First name",
      dataIndex: "firstName",
      width: 120,
      fixed: "left",
      render: (txt, rec) => txt || rec.fullName || "N/A",
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: 120,
      fixed: "left",
      render: (txt) => txt || "N/A",
    },
    {
      title: "user tag",
      dataIndex: "tags",
      width: 200,
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
      render: (text: any[], _: any) => text?.join(",") || "N/A",
    },
    {
      title: "Thefield contact",
      dataIndex: "thefieldContact",
      width: 180,
      render: (txt) => txt || "N/A",
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: "Fund",
      dataIndex: "fund",
      width: 110,
      ellipsis: {
        showTitle: true,
      },
      render: (txt) => txt || "N/A",
    },
    {
      title: "Interest",
      dataIndex: "interest",
      width: 200,
      className: "interest-col",
      render: (interest: string[]) => interest?.join(",") || "N/A",
      ellipsis: true,
    },
    {
      title: "Email address",
      dataIndex: "email",
      width: 180,
      ellipsis: {
        showTitle: true,
      },
      render: (txt) => txt || "N/A",
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      width: 150,
      render: (txt) => txt || "N/A",
    },
    {
      title: "Address 1",
      width: 200,
      dataIndex: ["addresses", 0],
      render: (txt) => txt || "N/A",
    },
    {
      title: "Address 2",
      width: 200,
      dataIndex: ["addresses", 1],
      render: (txt) => txt || "N/A",
    },
    {
      title: "City",
      dataIndex: "city",
      width: 80,
      render: (txt) => txt || "N/A",
    },
    {
      title: "State",
      dataIndex: "state",
      width: 80,
      render: (txt) => txt || "N/A",
    },
    {
      title: "Zipcode",
      dataIndex: "zipCode",
      width: 100,
      render: (txt) => txt || "N/A",
    },
    {
      title: "Country",
      dataIndex: "",
      width: 100,
      render: () => "United States",
    },
    {
      title: "date created",
      width: 130,
      dataIndex: "createDate",
      key: "createDate",
      render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "",
      width: 80,
      align: "center",
      fixed: "right",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["hover"]}
          overlayStyle={{ zIndex: 5 }}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item
                  onClick={() =>
                    handleAccessToUser(row.id, "/dashboard/setting")
                  }
                >
                  View donor's profile
                </Menu.Item>

                <Menu.Item
                  onClick={() =>
                    handleAccessToUser(row.id, "/dashboard/transactions")
                  }
                >
                  View donor's transactions
                </Menu.Item>
                <Menu.Item onClick={() => handleViewNote(row)}>
                  View admin note
                </Menu.Item>
                <Menu.Item onClick={() => handleDelete(row)}>
                  Delete account
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    ...initParam,
    receiver: receivers[0].value,
  });
  const [total, setTotal] = useState(0);
  const [amount, setAmount] = useState("0");
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [windowHeight] = useWindowSize()

  const getList = () => {
    const { date, ...others } = params,
      [startDate, endDate] = date || [null, null];
    let _data = {
      startDate: startDate?.toDate(),
      endDate: endDate?.toDate(),
      ...others,
    };
    donorList(_data)
      .then((data) => {
        setTableData(data.items);
        setTotal(data.meta.totalItems);
        setAmount(data.totalAmount || 0 + "");
      })
      .catch(() => {
        setTableData([]);
        setTotal(0);
      });
  };

  const [filterForm] = Form.useForm();
  const [filterModal, setFilterModal] = useState<any>(false);
  const handleViewFilter = () => {
    setFilterModal(true);
    filterForm.setFieldsValue(params);
  };

  const handleReset = () => {
    filterForm.resetFields();
    setParams(initParam);
  };

  const handleFilterFinish = (values: any) => {
    setSelectedRows([]);
    setTableData([]);
    setParams({
      ...params,
      ...values,
      page: 1,
      limit: 10,
    });
    setFilterModal(false);
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="admin-common-container admin-artists-container admin-donors-container">
      <h1>Donors</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="field-form-items">
            <span>donation to</span>
            <Select
              options={receivers}
              defaultActiveFirstOption
              bordered={false}
              mode="multiple"
              allowClear
              value={params.receiver}
              onChange={(e) => setParams({ ...params, page: 1, receiver: e })}
            />
          </div>
          <div className="field-form-items">
            <span>user tag</span>

            <Select
              value={params.tag}
              mode="multiple"
              bordered={false}
              allowClear
              onChange={(e) => setParams({ ...params, page: 1, tag: e })}
            >
              {tagList.map((item: any) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="flex">
            <div className="filter-item" onClick={handleViewFilter}>
              <Filter />
            </div>
            <span className="clear-all" onClick={handleReset}>
              Clear
            </span>
          </div>
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item key='e-p-t' onClick={handleExport}>Export CSV</Menu.Item>
              <Menu.Item key='r-p-t' onClick={showReport}>Run a report</Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      <div className="data-chart-container flex">
        <span>
          <i>Number of donors</i>
          <i>{total || 0}</i>
        </span>
        <span>
          <i>Contribution amount to The field</i>
          <i>$ {amount?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</i>
        </span>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Donors</span>
            <Input.Search
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 565 }}
      />
      {/* </div> */}
      <Modal
        width={670}
        title="Advanced filters"
        className="common-modal admin-filter-modal"
        visible={filterModal}
        onOk={() => filterForm.submit()}
        okText="Apply"
        cancelText="cancel"
        onCancel={() => setFilterModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={filterForm}
          onFinish={handleFilterFinish}
          layout="vertical"
          name="form_filter"
        >
          <Form.Item label="Search admin note" name="note">
            <Input allowClear placeholder="Search..." />
          </Form.Item>
          <Form.Item label="Donation to" name="receiver">
            <Select options={receivers} bordered={false} />
          </Form.Item>
          <Form.Item label="Search company" name="company">
            <Input allowClear placeholder="Search..." />
          </Form.Item>
          <Divider />
          <h4>User tag</h4>
          <div className="flex">
            <Form.Item label="Condition" name="tagCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="User tag" name="tag">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                {tagList.map((item: any) => (
                  <Select.Option key={item.name} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <h4>Interest</h4>
          <div className="flex">
            <Form.Item label="Condition" name="interestCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Interest" name="interest">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                {disciplineOptions.map((item: string) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <h4>The Field Contact</h4>
          <div className="flex">
            <Form.Item label="Condition" name="contactCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="the field contact" name="contact">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                <Select.Option value="JWC contact">JWC contact</Select.Option>
                <Select.Option value="Board contact">
                  Board contact
                </Select.Option>
                <Select.Option value="crowdfunding">Crowdfunding</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <Divider />
          <h4>Fund</h4>
          <div className="flex">
            <Form.Item label="Condition" name="fundCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="fund" name="fund">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                <Select.Option value="Annual Fund">Annual Fund</Select.Option>
                <Select.Option value="Giveaway">Giveaway</Select.Option>
                <Select.Option value="Benefit">Benefit</Select.Option>
                <Select.Option value="Add-on">Add-on</Select.Option>
                <Select.Option value="crowdfunding">Crowdfunding</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <Divider />
          <h4>Location</h4>
          <Form.Item label="City" name="city">
            <Input placeholder="City" maxLength={30} />
          </Form.Item>
          <Form.Item label="State" name="state" className="select-container">
            <Select bordered={false} placeholder="State" mode="multiple">
              {Object.values(stateList)?.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex">
            <Form.Item label="Zipcode Condition" name="zipcodeCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Zipcode" name="zipcode">
              <Input placeholder="Zipcode" maxLength={30} />
            </Form.Item>
          </div>
          <Form.Item
            label="country"
            name="country"
            className="select-container"
            initialValue="United States"
          >
            <Select bordered={false} placeholder="country">
              <Select.Option value="United States">United States</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        width={670}
        title="Admin notes"
        className="common-modal admin-user-modal"
        visible={!!noteModal}
        onOk={() => noteForm.submit()}
        okText="save changes"
        cancelText="cancel"
        onCancel={() => setNoteModal(undefined)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={noteForm}
          onFinish={handleNoteFinish}
          layout="vertical"
          name="form_note"
        >
          <Form.Item label="User tag" name="tags">
            <Select
              allowClear
              mode="multiple"
              placeholder="select..."
              bordered={false}
            >
              {tagList.map((item: any) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="add-user-tag" onClick={e => setTagModal(true)}>ADD A NEW USER TAG</div>
          <Form.Item label="fund" name="fund" >
            <Select
              allowClear
              mode="multiple"
              placeholder="select..."
              bordered={false}
            >
              <Select.Option value="Annual Fund">Annual Fund</Select.Option>
              <Select.Option value="Giveaway">Giveaway</Select.Option>
              <Select.Option value="Benefit">Benefit</Select.Option>
              <Select.Option value="Add-on">Add-on</Select.Option>
              <Select.Option value="crowdfunding">Crowdfunding</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="the field contact" name="contact" >
            <Select
              allowClear
              placeholder="select..."
              bordered={false}
            >
              <Select.Option value="JWC contact">
                JWC contact
              </Select.Option>
              <Select.Option value="Board contact">
                Board contact
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            className="textarea-wrap"
            label="Note"
            name="note"
          >
            < Input.TextArea maxLength={500} placeholder="Note..." />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={670}
        title={tagModal === true ? "Add new user tag" : "Edit user tag"}
        className="common-modal admin-user-modal"
        visible={!!tagModal}
        onOk={() => tagForm.submit()}
        okText="add"
        cancelText="cancel"
        okButtonProps={{
          loading,
        }}
        onCancel={() => setTagModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={tagForm}
          onFinish={handleTagFinish}
          layout="vertical"
          name="form_tag"
        >
          <p className="text-12">
            {tagModal === true
              ? "You are adding new tag to the system. You can use this tag to filter users later on."
              : "You can use this form below to add or edit the user tags that are assigned to this user."}
          </p>
          {tagModal === true ? (
            <>
              <Form.Item
                label="User tag"
                name="name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "User tag",
                  },
                ]}
              >
                <Input maxLength={250} placeholder="User tag" />
              </Form.Item>
              <p className="tag-list">
                {tagList?.map((item) => (
                  <Tag key={item.name}>{item.name}</Tag>
                ))}
              </p>
            </>
          ) : (
            <>
              <Form.Item noStyle name="id">
                <Input hidden disabled />
              </Form.Item>
              <Form.Item
                label="User tag"
                name="tags"
                rules={[
                  {
                    required: true,
                    type: "array",
                    message: "User tag",
                  },
                ]}
              >
                <Select
                  bordered={false}
                  placeholder="Select..."
                  mode="multiple"
                >
                  {tagList?.map((item) => (
                    <Select.Option key={item.name} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
      {deleteComponent}
      <DonorReportModal
        show={report}
        onCancel={hideReport}
        onOk={goReport}
      />
    </div>
  );
};
