import React from "react";
import { ReactComponent as Logo } from "@/images/logo-white.svg";
import SocialLayout from "@/components/layouts/SocialLayout";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import { NoTranslate } from "../GoogleTranslate";

interface props {
  social?: boolean;
}

const FooterLayout = ({ social = false }: props) => {
  const {
    store: { isMobile },
  } = useModel(view);

  return (
    <>
      {social && <SocialLayout />}
      <footer className="footer-container flex">
        <div className="flex-col">
          <span className="flex start">
            <Logo /> © 2024
          </span>
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://thefield.org/privacy-policy/"
            >
              Privacy Policy
            </a>
          </div>
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://thefield.org/terms-of-service/"
            >
              Terms of Service
            </a>
          </div>
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://thefield.org/accessibility/"
            >
              Accessibility
            </a>
          </div>
        </div>
        <div className="flex-col">
          <div className={isMobile ? "flex start" : "flex end"}>
            Phone: 212.691.6969
          </div>
          <div className={isMobile ? "flex start" : "flex end"}>
            Mailing Address:
          </div>
          <div className={isMobile ? "flex start" : "flex end"}>
            <NoTranslate>2​2​8 P​a​r​k A​v​e S, S​u​i​t​e 9​7​2​1​7</NoTranslate>
          </div>
          <div className={isMobile ? "flex start" : "flex end"}>
          <NoTranslate>N​e​w Y​o​r​k, N​Y 1​0​0​0​3-1502</NoTranslate>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterLayout;
