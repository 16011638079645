//created by Peggy on 2021/5/19
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Radio,
  Select,
  Table,
  Tag,
  Button,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import "./contribution.less";

import useConfirm from "@/components/Modal/Confirm";
import queryString from "query-string";
import { admin_router_finance_contribution_order } from "@/adminApp/routers";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { ReactComponent as Filter } from "@/images/filter.svg";
import { paymentTypes, statusTypes } from "@/types/enum";
import { artistList } from "@/adminApp/apis/artist-api";
import {
  contributionList,
  contributionTag,
  contributionCheck,
  contributionDelete,
  transactionCheck,
  orderEditTags,
} from "@/adminApp/apis/finance-api";
import DatePicker from "@/components/DatePicker";
import RemoteInput from "@/components/Input/RemoteInput";
import { stateList } from "@/types/enum";
import AmountInput from "@/components/Input/AmountInput";
import { useWindowSize } from "@/adminApp/models/model_admin_size";
import { eventCrowdList } from "@/adminApp/apis/event-api";
import { getArtistById } from "@/api/artist-api";
import { donorLastDonation, donorList } from "@/adminApp/apis/donor-api";
import InputComplete from "@/components/Input/InputRemote";
import { NoTranslate, TheField } from "@/components/GoogleTranslate";

export enum CheckTransactionType {
  ARTIST = "artist",
  CROWD = "crowd",
  ThE_FEILD = "thefield",
  GRANT_RUSH_FEE = "grant",
  CONSULT_FEE = "consult_fee",
  MISC_FEE = "misc_fee",
  CARRYOVER_FEE = "carryover_fee",
}
const List = () => {
  const history = useHistory();

  const [tagForm] = Form.useForm();
  const [tagModal, setTagModal] = useState<object | boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { setShow: deleteShow, component: deleteComponent } = useConfirm(
    <div className="flex column start align-start">
      <p>Are you sure you want to permanently delete this contribution ?</p>
    </div>,
    {
      title: "Delete ",
      className: "suspend-confirm-modal",
      okText: "Delete",
      onOk: (id?: any) => {
        contributionDelete(id).then(() => {
          getList();
          message.success("Delete successfully");
        });
      },
    }
  );

  //handler
  const handleExport = () => {
    const { page, limit, ...others } = params;

    window.open(
      `${
        process.env.REACT_APP_API_HOST
      }/contribution/export-csv?${queryString.stringify({
        ...others,
        finance: "contribution",
        exportId: selectedRows.map((item: any) => item.id).join(","),
        tz: dayjs.tz.guess(),
      })}`,
      "_blank"
    );
  };

  // -view note
  const [checkForm] = Form.useForm();
  const [checkModal, setCheckModal] = useState<CheckTransactionType>();
  const handleViewCheck = (row: any) => {
    setCheckModal(CheckTransactionType.ARTIST);
    checkForm.resetFields();
  };
  const [customizeFee,setCustomizeFee] = useState('No');
  const handleViewCheckToThefield = (row: any) => {
    setCheckModal(CheckTransactionType.ThE_FEILD);
    checkForm.resetFields();
  };
  const handleViewCheckTransaction = (row: any) => {
    setCheckModal(CheckTransactionType.CONSULT_FEE);
    checkForm.resetFields();
  };
  const handleCheckFinish = (values: any) => {
    const {
      amount,
      fee,
      checkNumber,
      date,
      artistId,
      otherContribution,
      crowdfunding,
      type,
      tags,
      ...others
    } = values;
    if (
      type &&
      ![
        CheckTransactionType.ARTIST,
        CheckTransactionType.CROWD,
        CheckTransactionType.ThE_FEILD,
      ].includes(type)
    ) {
      transactionCheck({
        amount: amount.replace(/,/g, ""),
        checkNumber,
        date,
        type,
        otherContribution,
        tags,
        donor: others,
      }).then((data) => {
        setCheckModal(undefined);
        getList();
        message.success("successfully");
      });
      return;
    }

    let crowdfundingId;
    if (crowdfunding) {
      crowdfundingId = parseInt(crowdfunding.split("_")[0]);
    }
    contributionCheck({
      amount: amount.replace(/,/g, ""),
      fee: fee?.toString().replace(/,/g, ""),
      checkNumber,
      date,
      tags,
      otherContribution,
      ...(crowdfundingId ? { crowdfundingId } : { artistId }),
      toTheField: checkModal === "thefield",
      donor: others,
    }).then((data) => {
      setCheckModal(undefined);
      setCustomizeFee('No')
      getList();
      message.success("successfully");
    });
  };

  const handleViewOrder = (id: any) => {
    window.open(admin_router_finance_contribution_order.replace(":id/:edit(edit)?", id), "_blank");
  };

  const handleShowTagModal = (row: any) => {
    setTagModal(row);
    if (row !== true) {
      const { id, tag } = row;
      tagForm.setFieldsValue({
        id,
        tags: tag || [],
      });
    }
  };

  const handleTagFinish = () => {
    setLoading(true);
    const values = tagForm.getFieldsValue();
    orderEditTags(values)
        .then(() => {
          message.success("Save successfully");
          tagForm.resetFields();
          setTagModal(false);
          getList();
        })
        .finally(() => setLoading(false));
  }

  // -view note
  const [noteForm] = Form.useForm();
  const [noteModal, setNoteModal] = useState<any>(false);
  const handleViewNote = (row: any) => {
    setNoteModal(true);
    const { donorNote } = row;
    noteForm.setFieldsValue({
      note: donorNote,
    });
  };

  const handleDelete = (row: any) => {
    deleteShow(row.id);
  };
  //handler end

  const [tagList, setTagList] = useState<any[]>([]);
  const getTags = () => {
    contributionTag().then((data) => setTagList(data));
  };

  useEffect(() => {
    getTags();
  }, []);

  // -search
  const column: any[] = [
    {
      title: "Product type",
      dataIndex: "productType",
      width: 130,
      fixed: "left",
    },
    {
      title: "Donation type",
      dataIndex: "donationType",
      width: 150,
      fixed: "left",
    },
    {
      title: "Receiver name",
      dataIndex: "receiverName",
      width: 150,
    },
    {
      title: "donor First Name",
      dataIndex: "donorName",
      width: 180,
      render: (text: string, item: any) =>
        item.donorFirstName || (text?.split(/\s+/) || [""])[0],
    },
    {
      title: "donor Last Name",
      dataIndex: "donorName",
      width: 180,
      render: (text: string, item: any) =>
        item.donorLastName || (text?.split(/\s+/) || ["", ""])[1],
    },
    {
      title: "donor email",
      dataIndex: "donorEmail",
    },
    {
      title: "donor address",
      dataIndex: "donorAddress",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Amount",
      width: 100,
      dataIndex: "amount",
      render: (text: string, row: any) =>
        Number(text).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Fee Covered",
      width: 130,
      dataIndex: "fees",
      render: (text: string, row: any) =>{
        if(text === '0'){
          return 'N/A'
        }else if(text !== '0' && row.cover === true){
          return 'YES'
        }else if(text !== '0' && row.cover === false){
          return 'NO'
        }
      }
    },
    {
      title: "Status",
      width: 100,
      dataIndex: "status",
      className: "artist-cell-roles",
      render: (text: string, _: any) => (
        <Tag color={statusTypes[text] ? statusTypes[text]?.color : "gray"}>
          {statusTypes[text] ? statusTypes[text]?.label : text}
        </Tag>
      ),
    },
    {
      title: "payment method",
      dataIndex: "paymentMethod",
      width: 180,
      key: "payment",
      render: (text: string, row: any) => paymentTypes[text || "_null"],
    },
    {
      title: "New / Renewal",
      dataIndex: "isNew",
      width: 140,
      key: "isNew",
      render: (text: string) => <Tag color={text === 'New' ? 'green' : "orange"}>{text}</Tag>,
    },
    {
      title: "Check number",
      width: 150,
      dataIndex: "checkNumber",
    },
    {
      title: "Invoice number",
      width: 150,
      dataIndex: "orderId",
    },
    {
      title: "transaction tag",
      dataIndex: "tag",
      className: "ellipsis-custom-cell",
      width: 180,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string[]) => text?.join(",") || "N/A",
    },
    {
      title: "Approved for QB",
      width: 170,
      dataIndex: "approveQuickBook",
      render: (text: string[]) => (text ? "Yes" : "No"),
    },
    {
      title: "Date on check",
      width: 150,
      dataIndex: "checkDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "Scheduled Pmt Date",
      width: 180,
      dataIndex: "schedulePaymentDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "Date created",
      width: 130,
      dataIndex: "createDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item onClick={() => handleViewOrder(row.id)}>
                  View order
                </Menu.Item>
                <Menu.Item onClick={() => handleViewOrder(row.id + "/edit")}>
                  Edit order
                </Menu.Item>
                <Menu.Item onClick={() => handleShowTagModal(row)}>
                  Edit transaction tag
                </Menu.Item>
                <Menu.Item onClick={() => handleViewNote(row)}>
                  View donor notes
                </Menu.Item>
                {/*<Menu.Item onClick={() => {}}>View donor info</Menu.Item>*/}
                {row.paymentMethod === "check" && (
                  <Menu.Item onClick={() => handleDelete(row)}>
                    Delete
                  </Menu.Item>
                )}
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const { search } = useLocation();
  const state = queryString.parse(search);
  const [text, setText] = useState("");
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
    status: Object.keys(statusTypes).filter((_) => _ !== "failed"),
    ...{
      ...(state as any),
      date: (state as any).date?.map((_: string) => dayjs(_)),
    },
  });
  const [total, setTotal] = useState(0);
  const [summary, setSummary] = useState<any>({
    number: 0,
    amount: 0,
  });
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [windowHeight] = useWindowSize()

  const [filterForm] = Form.useForm();
  const [filterModal, setFilterModal] = useState<any>(false);

  const handleViewFilter = () => {
    setFilterModal(true);
    filterForm.setFieldsValue(params);
  };
  const handleFilterFinish = ({ amount = ",", ...values }: any) => {
    setSelectedRows([]);
    setTableData([]);
    const [startAmount, endAmount] = amount?.split(",");
    setParams({
      ...params,
      ...values,
      startAmount,
      endAmount,
      page: 1,
      limit: 10,
    });
    setFilterModal(false);
  };

  const handleReset = () => {
    filterForm.resetFields();
    setText("");
    setParams({ page: 1, limit: 10, keyword: "" });
  };

  // const [bulkStatusForm] = useForm()
  // const { setShow: showBulkStatus, component: bulkStatusComp } = useConfirm(
  //   <div>
  //     <div className="des">
  //       You are assigning these following statuses to {selectedRows?.filter((item: any) => item.paymentMethod === 'check').length} selected transactions.
  //       <br />
  //       <br />
  //       <Form form={bulkStatusForm} layout='vertical'>
  //         <Form.Item
  //           label='Check transaction'
  //           name='status'
  //           rules={[{ required: true }]}
  //         >
  //           <Select allowClear placeholder="select..." bordered={false}>
  //             {Object.entries(statusTypes).map((item: any) => (
  //               <Select.Option key={item[0]} value={item[0]}>
  //                 {item[1].label}
  //               </Select.Option>
  //             ))}
  //           </Select>
  //         </Form.Item>
  //       </Form>
  //     </div>
  //   </div>,
  //   {
  //     title: 'Assign status to check transactions',
  //     okText: 'save',
  //     onOk: () => {
  //       const status = bulkStatusForm.getFieldValue('status')
  //       if (!status) {
  //         message.error('Please choose Status.')
  //         return
  //       }
  //       contributionBulkEditStatus({
  //         ids: selectedRows.map((_: any) => _.id),
  //         status
  //       }).then(res => {
  //         if (res?.success) {
  //           message.success('status updated!')
  //           getList()
  //         }
  //       })
  //     }
  //   }
  // )

  // const handleBulkEditStatus = () => {
  //   if (selectedRows.filter((_: any) => _.paymentMethod === 'check')?.length) {
  //     showBulkStatus(true)
  //     return
  //   }
  //   message.warn('This function is only available for check donation. Please make sure you select check transaction.')
  // }

  const getList = () => {
    let _data = params;
    contributionList({ ..._data, finance: "contribution" })
      .then(({ items, meta, summary }: any) => {
        setTableData(items);
        setSummary(summary);
        setTotal(meta.totalItems);
      })
      .catch(() => {
        setTotal(0);
        setTableData([]);
        setSummary(null);
      });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  const checkModalTitle = (type: CheckTransactionType) => {
    switch (type) {
      case CheckTransactionType.ARTIST:
      case CheckTransactionType.CROWD:
        return "Add check donation";
      case CheckTransactionType.ThE_FEILD:
        return "Add check donation to The Field";
      default:
        return "Add a transaction by check";
    }
  };

  return (
    <div className="contribution-container admin-common-container">
      <h1>Contributions</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="field-form-items">
            <span>Product type</span>
            <Select
              allowClear
              mode="multiple"
              value={params?.productType}
              bordered={false}
              onChange={(e) => {
                setSelectedRows([]);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  productType: e,
                });
              }}
              placeholder="Select product types"
            >
              <Select.Option value="donation_to_the_field">
                Donate to <TheField/>
              </Select.Option>
              <Select.Option value="donation_to_artist">
                Donate to Artist
              </Select.Option>
              <Select.Option value="crowdfunding">Crowdfunding</Select.Option>
            </Select>
          </div>
          <div className="field-form-items">
            <span>Transaction tag</span>
            <Select
              allowClear
              value={params?.tag}
              placeholder="Select transaction tags"
              mode="multiple"
              bordered={false}
              onChange={(e) => {
                setSelectedRows([]);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  tagCondition: "one_of",
                  tag: e,
                });
              }}
            >
              {tagList.map((item: any) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="field-form-items">
            <span>Status</span>
            <Select
              allowClear
              mode="multiple"
              value={params?.status}
              bordered={false}
              onChange={(e) => {
                setSelectedRows([]);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  statusCondition: "one_of",
                  status: e,
                });
              }}
              placeholder="select... "
            >
              {Object.entries(statusTypes).map((item: any) => (
                <Select.Option key={item[0]} value={item[0]}>
                  {item[1].label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item" onClick={handleViewFilter}>
            <Filter />
          </div>
          <span className="clear-all" onClick={handleReset}>
            Clear
          </span>
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={handleExport}>Export CSV</Menu.Item>
              <Menu.Item onClick={handleViewCheck}>
                Add check donation
              </Menu.Item>
              <Menu.Item onClick={handleViewCheckToThefield}>
                Add check donation to TheField
              </Menu.Item>
              <Menu.Item onClick={handleViewCheckTransaction}>
                Add check transaction
              </Menu.Item>
              {/* <Menu.Item onClick={handleBulkEditStatus}>
                Bulk edit check transaction status
              </Menu.Item> */}
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      <div className="data-chart-container flex">
        <span>
          <i>Number of contributions </i>
          <i>
            {summary?.number
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
          </i>
        </span>
        <span>
          <i>Contribution amount</i>
          <i>
            ${" "}
            {summary?.amount
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
          </i>
        </span>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Contributions</span>
            <Input.Search
              value={text}
              onChange={(e) => setText(e.target.value)}
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          showTotal: (total: any) => `Total contributions :  ${total} `,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 565 }}
      />
      {/* </div> */}
      <Modal
        width={670}
        title="Advanced filters"
        className="common-modal admin-filter-modal"
        visible={filterModal}
        onOk={() => filterForm.submit()}
        okText="Apply"
        cancelText="cancel"
        onCancel={() => setFilterModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={filterForm}
          onFinish={handleFilterFinish}
          layout="vertical"
          name="form_filter"
        >
          <Form.Item label="Search donor note" name="donorNote">
            <Input allowClear placeholder="Search..." />
          </Form.Item>
          <Form.Item label="Search admin note" name="adminNote">
            <Input allowClear placeholder="Search..." />
          </Form.Item>
          <Form.Item label="date created" name="date">
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item label="Scheduled payment date" name="schedulePaymentDate">
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item label="New / Renewal" name="isNew">
            <Select allowClear placeholder="select..." bordered={false}>
              <Select.Option value="New">New</Select.Option>
              <Select.Option value="Renewal">Renewal</Select.Option>
            </Select>
          </Form.Item>
          <Divider />
          <h4>Transaction tag</h4>
          <div className="flex">
            <Form.Item label="Condition" name="tagCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Transaction tag" name="tag">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                {tagList.map((item: any) => (
                  <Select.Option key={item.name} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <h4>Payment method</h4>
          <div className="flex">
            {/* <Form.Item label="Condition" name="paymentMethodCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item> */}
            <Form.Item label="Payment method" name="paymentMethod">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                {Object.entries(paymentTypes)
                  ?.slice(0, 3)
                  .map((item: any) => (
                    <Select.Option key={item[0]} value={item[0]}>
                      {item[1]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <h4>Fee Covered?</h4>
          <div className="flex">
            {/* <Form.Item label="Condition" name="paymentMethodCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item> */}
            <Form.Item label="fee covered?" name="cover">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                <Select.Option value="yes">Yes</Select.Option>
                <Select.Option value="no">No</Select.Option>
                <Select.Option value="na">N/A</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <h4>Product type</h4>
          <div className="flex">
            <Form.Item label="Condition" name="productTypeCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="product type" name="productType">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                <Select.Option value="donation_to_the_field">
                  Donate to <TheField/>
                </Select.Option>
                <Select.Option value="donation_to_artist">
                  Donate to Artist
                </Select.Option>
                <Select.Option value="crowdfunding">Crowdfunding</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <Divider />
          <h4>Status</h4>
          <div className="flex">
            <Form.Item label="Condition" name="statusCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="status" name="status">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                {Object.entries(statusTypes).map((item: any) => (
                  <Select.Option key={item[0]} value={item[0]}>
                    {item[1].label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <h4>Amount</h4>
          <Form.Item label="Amount" name="amount">
            <Select allowClear placeholder="Select a range" bordered={false}>
              <Select.Option value="0,100">Less than $100</Select.Option>
              <Select.Option value="100,500.001">$100 - $500</Select.Option>
              <Select.Option value="500,1000.001">$500 - $1000</Select.Option>
              <Select.Option value="1000.001,">Larger than $1000</Select.Option>
            </Select>
          </Form.Item>
          <Divider />

          <h4>Approved for QB</h4>
          <Form.Item label="Approved for QB" name="approvedQuickBook">
            <Select allowClear placeholder="Select types" bordered={false}>
              <Select.Option value="true">Yes</Select.Option>
              <Select.Option value="false">No</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={670}
        title="Edit transaction tag"
        className="common-modal admin-user-modal"
        visible={!!tagModal}
        onOk={() => handleTagFinish()}
        okText="save"
        cancelText="cancel"
        okButtonProps={{
          loading,
        }}
        onCancel={() => setTagModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={tagForm}
          layout="vertical"
          name="form_tag"
        >
          <p className="text-12">You can use this form below to add or edit the transaction tags that are assigned to this order.</p>
              <Form.Item noStyle name="id">
                <Input hidden disabled />
              </Form.Item>
              <Form.Item
                label="Transaction tag"
                name="tags"
                rules={[
                  {
                    type: "array",
                    message: "Transaction tag",
                  },
                ]}
              >
                <Select
                  bordered={false}
                  placeholder="Select..."
                  mode="multiple"
                >
                  {tagList?.map((item) => (
                    <Select.Option key={item.name} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={670}
        title={checkModalTitle(checkModal!)}
        className="common-modal admin-check-modal"
        visible={!!checkModal}
        onOk={() => checkForm.submit()}
        okText="save"
        cancelText="cancel"
        onCancel={() => {
          setCheckModal(undefined)
          setCustomizeFee('No')
        }}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={checkForm}
          onFinish={handleCheckFinish}
          onFinishFailed={(e) => console.log(e)}
          onValuesChange={(chg) => {
            console.log(chg)
            if (chg["crowdfunding"]) {
              const [, id] = chg["crowdfunding"].split("_");
              getArtistById({ id }, true, true).then((artist) => {
                artist &&
                  checkForm.setFieldsValue({
                    crowdArtist:
                      artist.legalName ||
                      artist.displayName ||
                      `${artist.firstName} ${artist.lastName}`,
                  });
              });
            }
          }}
          layout="vertical"
          name="form_note"
        >
          {(checkModal === CheckTransactionType.CROWD ||
            checkModal === CheckTransactionType.ARTIST) && (
            <>
              <h2>Recipient information</h2>
              <Checkbox
                onChange={(v) => {
                  setCheckModal(
                    v.target.checked
                      ? CheckTransactionType.CROWD
                      : CheckTransactionType.ARTIST
                  );
                  checkForm.resetFields();
                }}
                style={{ marginBottom: "12px" }}
              >
                This check is for a crowdfunding campaign{" "}
              </Checkbox>
              {checkModal === CheckTransactionType.CROWD && (
                <Form.Item
                  label="Search for a crowdfunding campaign "
                  name="crowdfunding"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Search for a crowdfunding campaign ",
                    },
                  ]}
                >
                  <RemoteInput
                    placeholder="Search for a crowdfunding campaign "
                    fetchOptions={async (e) => {
                      let data = await eventCrowdList(
                        {
                          keyword: e,
                          page: 1,
                          limit: 1000,
                        },
                        true
                      );
                      return data?.items.map((item: any) => ({
                        value: `${item.id}_${item.userId}`,
                        label: item.name,
                      }));
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item
                label={
                  checkModal === CheckTransactionType.ARTIST
                    ? "Search for SA Artist"
                    : "SA Artist"
                }
                name={
                  checkModal === CheckTransactionType.ARTIST
                    ? "artistId"
                    : "crowdArtist"
                }
                rules={[
                  {
                    required: checkModal === CheckTransactionType.ARTIST,
                    type:
                      checkModal === CheckTransactionType.ARTIST
                        ? "number"
                        : "string",
                    whitespace: true,
                    message: "Search for SA Artist",
                  },
                ]}
              >
                {checkModal === CheckTransactionType.ARTIST ? (
                  <RemoteInput
                    placeholder="Search for SA Artist"
                    fetchOptions={async (e) => {
                      let data = await artistList(
                        {
                          keyword: e,
                          membershipType: "active",
                          gracePeriod: 30,
                          page: 1,
                          limit: 1000,
                        },
                        true
                      );
                      return data?.items.map((item: any) => ({
                        value: item.userId,
                        label:
                          item.legalName ||
                          item.displayName ||
                          `${item.firstName} ${item.lastName}`,
                      }));
                    }}
                  />
                ) : (
                  <Input placeholder="SA Artist" disabled />
                )}
              </Form.Item>
            </>
          )}
          {checkModal !== CheckTransactionType.ARTIST &&
            checkModal !== CheckTransactionType.CROWD &&
            checkModal !== CheckTransactionType.ThE_FEILD && (
              <Form.Item
                name="type"
                label="Product Type"
                initialValue={CheckTransactionType.CONSULT_FEE}
                rules={[{ required: true, message: "transaction type" }]}
              >
                <Select bordered={false}>
                  <Select.Option value={CheckTransactionType.CONSULT_FEE}>
                    Consultation Fee
                  </Select.Option>
                  <Select.Option value={CheckTransactionType.CARRYOVER_FEE}>
                    Carryover Fee
                  </Select.Option>
                  <Select.Option value={CheckTransactionType.MISC_FEE}>
                    Misc Fee
                  </Select.Option>
                  <Select.Option value={CheckTransactionType.GRANT_RUSH_FEE}>
                    GrantApplication Rush Fee
                  </Select.Option>
                </Select>
              </Form.Item>
            )}
          <h2>Check information</h2>
          <Form.Item
            label="Check number"
            name="checkNumber"
            normalize={(e: string) =>
              e
                .replace(/[^\d]*/g, "")
                .replace(/^(\d{11})/, (_) => _.substr(0, 10) + "-" + _[10])
                .replace(/-(\d{8})/, (_) => _.substr(0, 8) + "-" + _[8])
            }
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Check number",
              },
            ]}
          >
            <Input maxLength={24} placeholder="Check number" />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                whitespace: false,
                message: "Amount",
              },
            ]}
          >
            <AmountInput placeholder="Enter amount" 
                onChange={()=>{
                  const amount = checkForm.getFieldValue('amount') || 0;
                  let fee = checkForm.getFieldValue('fee') || 0;
                  if(customizeFee === 'No'){
                    fee = Number((amount * 0.07).toFixed(2));
                  }
                  const receivedAmount = amount - fee
                  checkForm.setFieldsValue({ fee, receivedAmount })
                }}/>
          </Form.Item>
          {(checkModal === CheckTransactionType.ARTIST ||
            checkModal === CheckTransactionType.CROWD) && <>
            <div className="customizeFee">Customize fee</div>
            <Radio.Group className="customizeFee-radio" onChange={(e)=>{
              setCustomizeFee(e.target.value)
              if(e.target.value === 'No'){
                const amount = checkForm.getFieldValue('amount') || 0;
                const fee = Number((amount * 0.07).toFixed(2));
                const receivedAmount = amount - fee;
                checkForm.setFieldsValue({ fee, receivedAmount })
              }
            }} value={customizeFee}>
              <Radio value="No">No</Radio>
              <Radio value="Yes">Yes</Radio>
            </Radio.Group>
            <Form.Item
              label="Fee"
              name="fee"
              rules={[
                {
                  required: false,
                  whitespace: true,
                  message: "Fee",
                  validator: ()=>{
                    return Promise.resolve()
                  }
                },
              ]}
            >
              <AmountInput
                placeholder="Enter fee"
                disabled={customizeFee === 'No'}
                onChange={()=>{
                  const amount = checkForm.getFieldValue('amount') || 0;
                  const fee = checkForm.getFieldValue('fee') || 0;
                  const receivedAmount = amount - fee;
                  checkForm.setFieldsValue({ receivedAmount })
                }}
              />
            </Form.Item>
            <Form.Item
              label="Received amount"
              name="receivedAmount"
              rules={[
                {
                  required: false,
                  whitespace: true,
                  message: "Received amount",
                  validator: ()=>{
                    return Promise.resolve()
                  }
                },
              ]}
            >
              <AmountInput
                placeholder="Received amount"
                disabled={true}
              />
            </Form.Item>
            
            </>}
          <Form.Item
            label="Donation date"
            name="date"
            rules={[
              {
                required: true,
                message: "Donation date",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Divider />
          <h2>Donor information</h2>
          <Form.Item
            label="email address"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                whitespace: true,
                message: "email address",
              },
            ]}
          >
            <InputComplete
                placeholder="Search email address"
                onSelect={(e,data)=>{
                  donorLastDonation({donorEmail:data.email}).then(e => {
                    if(e?.detail?.donor){
                      checkForm.setFieldsValue({...e.detail.donor})
                    }
                  })
                }}
                fetchOptions={async (e) => {
                  let data = await donorList({
                    keyword: e,
                    page: 1,
                    limit: 1000,
                  });
                  return data?.items.map((item: any) => ({
                    ...item,
                    value: item.email,
                    label: item.email ,
                  }));
                }}
              />
          </Form.Item>
          <Form.Item
            label="Donor name on check"
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Donor name",
              },
            ]}
          >
            <Input maxLength={50} placeholder="Donor name" />
          </Form.Item>
          <Form.Item
            label="Donor public name"
            name="publicName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Donor public name",
              },
            ]}
          >
            <Input maxLength={50} placeholder="Donor public name" />
          </Form.Item>
          <Form.Item
            label="address"
            name="address"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Address",
              },
            ]}
          >
            <Input maxLength={200} placeholder="Address" />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "City",
              },
            ]}
          >
            <Input placeholder="City" maxLength={30} />
          </Form.Item>
          <div className="flex">
            <Form.Item
              label="State"
              name="state"
              className="select-container"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "State",
                },
              ]}
            >
              <Select bordered={false} placeholder="State">
                {Object.values(stateList)?.map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Zip Code"
              name="zipCode"
              rules={[
                {
                  required: false,
                  whitespace: true,
                  message: "Zip Code",
                },
              ]}
            >
              <Input placeholder="Zip Code" maxLength={5} />
            </Form.Item>
          </div>
          <Form.Item label="Transaction tags" name="tags">
            <Select
              allowClear
              placeholder="Select transaction tags"
              mode="multiple"
              bordered={false}
            >
              {tagList.map((item: any) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <p className="text-12">
              IMPORTANT: Please make sure to physically mail your check to us at:
              <div translate="no">The Field</div>
              <div translate="no">228 Park Ave S</div>
              <div translate="no">Suite 97217</div>
              <div translate="no">New York, NY 10003-1502.</div>
            </p>
        </Form>
      </Modal>
      <Modal
        width={670}
        title="Donor note"
        className="common-modal admin-user-modal"
        visible={noteModal}
        forceRender
        centered
        maskClosable={false}
        onCancel={() => setNoteModal(false)}
        footer={[
          <Button type="primary" onClick={() => setNoteModal(false)}>
            ok
          </Button>,
        ]}
      >
        <Form form={noteForm} layout="vertical" name="form_note">
          <Form.Item
            className="textarea-wrap"
            label="Note"
            name="note"
            rules={[
              {
                whitespace: true,
                message: "note",
              },
            ]}
          >
            <Input.TextArea disabled maxLength={50} placeholder="Note..." />
          </Form.Item>
        </Form>
      </Modal>
      {deleteComponent}
      {/* {bulkStatusComp} */}
    </div>
  );
};

export default List;
