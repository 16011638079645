import {
  Checkbox,
  Radio,
  Form,
  Input,
  Select,
  Button,
  Spin,
  message,
  Breadcrumb,
} from "antd";
import DatePicker, { TimePicker } from "@/components/DatePicker";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import UploadCrop from "@/components/UploadAndCrop/Cropperjs";
import "./grant_post.less";
import dayjs from "dayjs";

import {
  disciplineOptions,
  locationStatesOptions,
  timezoneH,
} from "@/types/enum";
import { useLoading, useModel } from "use-reaction";
import {
  getGrantAction,
  model_grant_post,
  putGrantAction,
  upGrantFieldAction,
} from "@/adminApp/models/model_grant_post";
import { useHistory } from "react-router-dom";
import { admin_router_grant_view } from "@/adminApp/routers";
import { TextEditor } from "@/components/TextEditor/TextEditor";

export const GrantPost = ({ id }: { id?: number }) => {
  const isEdit = !!id;
  const { store: info, doAction } = useModel(model_grant_post);
  const loading = useLoading(model_grant_post);
  const history = useHistory();
  const [requireSponsorship, setRequireSponsorship] = useState(
    info.requireSponsorship
  );
  const [formRef] = Form.useForm();
  const dateFormat = "MM/DD/YYYY";
  useEffect(() => {
    formRef.setFieldsValue({
      ...info,
      location: info?.location ? info?.location : undefined,
      discipline: info.discipline || undefined,
    });
    setRequireSponsorship(info.requireSponsorship);
    // eslint-disable-next-line
  }, [info.id]);
  useEffect(() => {
    isEdit && doAction(getGrantAction, id, "model");
    // eslint-disable-next-line
  }, [isEdit]);

  const onFinish = async (data: any) => {
    data.endtime = info.endtime;
    data.requireSponsorship = requireSponsorship;
    const res = await doAction(putGrantAction, data, "model");
    if (res && res.id) {
      message.success("save succeed.");
    }
  };

  const toPreview = async () => {
    if (formRef) {
      try {
        const data = await formRef.validateFields();
        data.endtime = info.endtime;
        data.requireSponsorship = requireSponsorship;

        await doAction(putGrantAction, data, "model");
        // goto preview page
        history.push(
          admin_router_grant_view.replace(":id", info.id!) + "?preview=true"
        );
      } catch (error) {
        // form validate failed
      }
    }
  };

  return (
    <div className="grant-post">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/application/grants-list">
          Grants
        </Breadcrumb.Item>
        <Breadcrumb.Item>Post a grant</Breadcrumb.Item>
      </Breadcrumb>
      <h2 className="title">{isEdit ? "Edit" : "Post"} a Grant</h2>
      <div className="des">
        To publish a grant opportunity, complete the form below. You will have
        the opportunity to review and edit this content before publishing.
      </div>
      <Spin spinning={loading}>
        <Form form={formRef} onFinish={onFinish} layout="vertical">
          <h3 className="sub-title">Grant information</h3>
          <Form.Item name="requireSponsorship" noStyle>
            <Checkbox
              className="sponsor-check"
              checked={requireSponsorship}
              onChange={(e) => setRequireSponsorship(e.target.checked)}
            >
              This Grant requires fiscal sponsorship
            </Checkbox>
          </Form.Item>
          <Form.Item
            label="Grant name"
            name="name"
            rules={[{ required: true, message: "please fill grant name" }]}
          >
            <Input placeholder="Enter Grant name" />
          </Form.Item>

          <Form.Item label="Grant organization" name="organization">
            <Input placeholder="Enter Grant organization" />
          </Form.Item>
          <Form.Item label="Grant link" name="link">
            <Input placeholder="Enter the external link to the Grant" />
          </Form.Item>
          <Form.Item label="Grant award" name="award">
            <Input placeholder="Enter grant award" />
          </Form.Item>
          <Form.Item
            label="Short description"
            className="textarea-wrap"
            rules={[
              {
                validator: (_, value) => {
                  if (value?.split(/\s+/g)?.length > 200) {
                    return Promise.reject(
                      new Error("Short description is limited to 200 words")
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
                validateTrigger: "blur",
              },
            ]}
            name="summary"
          >
            <TextArea
              autoSize={{ minRows: 3 }}
              placeholder="Short description in 200 characters maximum. This short description will show on the Grant thumbnail in the Grant discovery page. "
            />
          </Form.Item>
          <Form.Item label="Grant description" name="description">
            <TextEditor placeholder="Enter Grant description" />
          </Form.Item>
          <Form.Item label="Discipline" name="discipline">
            <Select
              bordered={false}
              mode="multiple"
              className="multiple-row-selector"
              placeholder="Select all that apply"
            >
              {disciplineOptions.map((d) => (
                <Select.Option key={`d-${d.replace(/\s/, "-")}`} value={d}>
                  {d}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="row flex">
            <Form.Item label="Contact Email" name="email">
              <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item label="Phone Number" name="phone">
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </div>
          <Form.Item
            label="Eligible Location"
            name="location"
            initialValue={[]}
          >
            <Select
              bordered={false}
              allowClear
              className="multiple-row-selector"
              mode="multiple"
              placeholder="Select all that apply"
              defaultActiveFirstOption
            >
              {locationStatesOptions.map((l) => (
                <Select.Option key={`l-${l.replace(/\s/, "-")}`} value={l}>
                  {l}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <h3 className="sub-title">Deadline</h3>
          <Form.Item
            name="deadlineType"
            rules={[
              {
                required: true,
              },
            ]}
            noStyle
          >
            <Radio.Group
              value={info.deadlineType}
              onChange={(e) =>
                doAction(upGrantFieldAction, { deadlineType: e.target.value })
              }
            >
              <Radio value="hard" defaultChecked>
                Hard Deadline
              </Radio>
              <Radio value="rolling">Rolling Deadline or No Deadline </Radio>
            </Radio.Group>
          </Form.Item>
          {info.deadlineType === "hard" ? (
            <Form.Item
              label="Deadline"
              name="deadline"
              rules={[
                {
                  required: info.deadlineType === "hard",
                  message: "please choose the deadline date",
                },
              ]}
            >
              {" "}
              <DatePicker
                allowClear={false}
                format={dateFormat}
                value={(info?.deadline && dayjs(info.deadline)) || ""}
                onChange={(date, dateStr) => {
                  formRef.setFieldsValue({ deadline: dateStr });
                  doAction(upGrantFieldAction, { deadline: dateStr });
                }}
              />{" "}
            </Form.Item>
          ) : (
            <Form.Item label="Deadline" name="rolling">
              <Input
                value={info.rolling}
                onChange={(e) => {
                  formRef.setFieldsValue({ rolling: e.target.value });
                  doAction(upGrantFieldAction, { rolling: e.target.value });
                }}
              />
            </Form.Item>
          )}
          {info.deadlineType === "hard" && (
            <div className="row flex">
              <Form.Item
                label="timezone"
                name="timezone"
                rules={[
                  {
                    required: info.deadlineType === "hard",
                    message: "please choose a timezone",
                  },
                ]}
              >
                <Select bordered={false} placeholder="Select timezone">
                  {timezoneH.map((t) => (
                    <Select.Option key={t.value} value={t.value}>
                      {t.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Endtime"
                rules={[
                  {
                    required: info.deadlineType === "hard",
                    message: "please choose a timezone",
                  },
                ]}
              >
                <TimePicker
                  use12Hours
                  format="hh:mm a"
                  value={dayjs("2021-08-08 " + info?.endtime)}
                  onChange={(time, timeStr) => {
                    // formRef.setFieldsValue({ endtime: timeStr })
                    doAction(upGrantFieldAction, { endtime: timeStr });
                  }}
                />
              </Form.Item>
            </div>
          )}
          <h3 className="sub-title">Media</h3>
          <Form.Item label="Grant thumb photo" noStyle name="thumb">
            <UploadCrop
              folder="admin_grant"
              listType="text"
              showUploadList
              maxCount={1}
              scale={2.5} // 500/200
            >
              <Button className="upload-btn">upload photo</Button>
            </UploadCrop>
          </Form.Item>
          <div className="row flex end">
            <Button htmlType="submit" className="btn-draft">
              Save as draft
            </Button>
            <Button
              type="primary"
              className="btn-preview"
              onClick={(e) => toPreview()}
            >
              Preview
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
