import { useEffect, useState, memo, useMemo } from "react";
import "./TextEditor.less";
import BraftEditor, { EditorState, ControlType } from "braft-editor";
import "braft-editor/dist/index.css";
import { FileImageFilled } from "@ant-design/icons";

interface Props {
  placeholder?: string;
  toolbarCfg?: ControlType[];
  value?: string;
  onChange?: (value: string) => void;
}

const default_toolbar_cfg: ControlType[] = [
  "bold",
  "italic",
  "underline",
  "font-size",
  "headings",
  "text-align",
  "text-indent",
  "list-ul",
  "list-ol",
  "link",
  {
    key: "media",
    title: "image",
    text: <FileImageFilled />,
  },
];

const uploadCallback = async ({ file, success, error }: any) => {
  try {
    const name = `${Date.now()}/${file.name}`;

    const data = await new ((window as any)?.AWS).S3.ManagedUpload({
      params: {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: "description_media/" + name,
        Body: file,
      },
    });
    const res = await data.promise();
    // return { data: { link: res.Location } }
    success({
      url: res.Location,
    });
  } catch (e) {
    console.error(e);
    error({ msg: (e as unknown as any)?.message || "" });
  }
};

const TextEditorComp = memo(
  ({
    placeholder,
    value,
    onChange,
    toolbarCfg = default_toolbar_cfg,
  }: Props) => {
    const [inited, setInited] = useState(false);
    const [st, setSt] = useState<EditorState>(
      BraftEditor.createEditorState(null)
    );
    const onSateChange = (st: EditorState) => {
      setSt(st);
      onChange && onChange(st.toHTML());
    };

    useEffect(() => {
      if (!inited && value) {
        setInited(true);
        if (value !== st.toHTML()) {
          setSt(BraftEditor.createEditorState(value));
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <BraftEditor
        onChange={onSateChange}
        className="text-editor-comp"
        value={st}
        language="en"
        hooks={{
          "toggle-link": ({ href, target }) => {
            href = href.indexOf("http") === 0 ? href : `https://${href}`;
            return { href, target };
          },
          "set-image-link": (href) => {
            // console.log(e);
            href = href.indexOf("http") === 0 ? href : `https://${href}`;
            return href;
          },
        }}
        placeholder={placeholder}
        controls={toolbarCfg}
        lineHeights={[1.5]}
        allowInsertLinkText
        media={{
          accepts: {
            image:
              "image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg",
            video: false,
            audio: false,
          },
          uploadFn: uploadCallback,
        }}
      />
    );
  }
);
export const TextEditor = (props: Props) =>
  useMemo(() => <TextEditorComp {...props} />, [props]);
