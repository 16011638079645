//created by Peggy on 2021/2/10
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Button, message, Modal, Popover, Radio, Space, Spin, Tag } from "antd";
import { CrowdPublishPopup, InfoModal } from "./Campaign";
import styled from "styled-components";

import HomeLayout from "@/components/layouts/HomeLayout";
import CrowdItem from "@/pages/homepage/donation/CrowdItem";

import { crowGet, crowPut, reportCrowd } from "@/api/donation-api";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";

import "@/styles/home/preview.less";
import "@/styles/home/user.less";
import { useModel } from "use-reaction";
import { view } from "@/model/view";

import avatar_img from "@/images/avatar.svg";
import Caption from "@/components/Image/caption";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import tz from "dayjs/plugin/timezone"
import { timezoneHText, UserRole } from "@/types/enum";
import { localStorageGet } from "@/utils/storage";
import { admin_router_crowd_field, admin_router_crowd_field_donation_detail } from "@/adminApp/routers";
import { useLoadingHandler } from "@/libs/fetch";
import { AppContainerStyle } from "@/styles/util";
import { NoTranslate } from "@/components/GoogleTranslate";
dayjs.extend(utc)
dayjs.extend(tz)

const PreviewContainer = styled.div``;

const Preview = () => {
  const { id, preview } = useParams() as any;
  const history = useHistory();
  const {
    store: { isMobile },
  } = useModel(view);
  const [reportReason, setReportReason] = useState("spam");
  const [showReport, setShowReport] = useState(false);
  const [show, setShow] = useState(false);
  const [showPub, setShowPub] = useState(false);

  const [endingToday, setEndingToday] = useState(false);
  const [info, setInfo] = useState<any>("");
  const roles = localStorageGet("roles");
  const isAdmin = roles?.includes(UserRole.ADMIN);
  const detailPath = isAdmin ? admin_router_crowd_field_donation_detail : '/dashboard/donation/detail/:id'
  const [showloading] = useLoadingHandler();

  const handleSave = (type: string) => {
    let _data = {
      status: type,
    };
    if (type === 'live') {
      setShowPub(true)
    } else {
      crowPut(info.id, _data).then((data) => {
        history.push(isAdmin ? admin_router_crowd_field : `/dashboard/donation/list`);
      });
    }
  };
  useEffect(() => {
    let _data = {
      id,
    };
    crowGet(_data).then((data) => {
      setInfo(data);
      const timeEnd = data.time?.end || data.endDate?.replace(/(\.\d+)/, '')
      const timezone = data.time?.timezone || 'EST'
      const ending = dayjs(timeEnd).tz(timezoneHText(timezone), true)
      const diff = ending.diff(dayjs(), 'day', true)
      setEndingToday(0 < diff && diff < 1)
    });
  }, [id]);

  return (
    <>
      {showloading && isAdmin && <Spin className="global-spin-container"></Spin>}

      {info?.status === "draft" && preview === "preview" && (
        <div className="preview-btn flex">
          <span>
            <i>Crowdfunding campaign </i>preview
          </span>
          <div className="btn-item">
            <Button
              type={isMobile ? "text" : "default"}
              onClick={() => handleSave("draft")}
            >
              Save <i> as draft</i>
            </Button>
            <Button type="primary" onClick={() => handleSave("live")}>
              Publish
            </Button>
          </div>
        </div>
      )}
      <AppContainerStyle/>
      <HomeLayout hideSign={isAdmin} hideMenu={isAdmin} >
        <PreviewContainer className="common-container preview-container user-profile-container donation-preview-container flex column start align-start">
          <section className="donate-info">
            <p className="text">CROWDFUNDING campaign &nbsp;
              {endingToday && <Tag color="orange">Ends Today</Tag>}
            </p>
            <h2 className="flex start">
              <NoTranslate>{info?.name}</NoTranslate>
              <Popover
                trigger={['hover', 'click']}
                overlayClassName="popover-btn-wrap"
                content={
                  <div className="share">
                    <p>
                      <span>Share campaign</span>
                      <CopyToClipboard
                        text={window.location.href}
                        onCopy={() => message.success("Copy success")}
                      >
                        <p className="flex">
                          <i>{window.location.href}</i>
                          <i>Copy</i>
                        </p>
                      </CopyToClipboard>
                    </p>
                    <Button onClick={() => setShowReport(true)}>
                      Report this campaign
                    </Button>
                  </div>
                }
              >
                <span className="filter-item">
                  <FilterMore />
                </span>
              </Popover>
            </h2>
            <p
              className="content-user"
              onClick={() =>
                !isAdmin && (
                  !info?.artist?.id ? window.open('https://thefield.org', '_blank') :
                  history.push(
                    `/profile/${(info?.artist?.displayName || "-").replace(
                      /[^\w]/g,
                      "-"
                    )}/${info?.artist?.id}`
                  )
                )
              }
            >
              <img src={info?.artist?.avatar || avatar_img} alt="" />{" "}
              <NoTranslate>{info?.artist?.displayName}</NoTranslate>
            </p>
            <h4>{info?.summary}</h4>
            <CrowdItem
              time={info.time || { start: info.startDate, end: info.endDate }}
              timezone={info.time?.timezone || 'EST'}
              goal={info?.goalAmount}
              raised={info?.currentAmount}
              donors={info?.donateNumber}
              status={info?.status !== "live"}
              to={`/home/donation/crowd/${info?.id}/0`}
            />
          </section>
          <section className="media-play-info">
            <div className={`poster-wrap mb-24`}>
              {(info?.youtubeUrl && (
                <Plyr
                  source={
                    info?.youtubeUrl?.indexOf("youtu") > -1
                      ? {
                        type: "video",
                        sources: [
                          {
                            src:
                              info?.youtubeUrl
                                ?.match(
                                  /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
                                )
                                ?.pop() || "acc",
                            provider: "youtube",
                          },
                        ],
                      }
                      : {
                        type: "video",
                        sources: [
                          {
                            src:
                              info?.youtubeUrl
                                ?.match(/vimeo\.com\/([\d]{8,})/)
                                ?.pop() || "acc",
                            provider: "vimeo",
                          },
                        ],
                      }
                  }
                />
              )) || (
                  <Caption
                    className="feature-photo"
                    src={info?.featurePhotos && info?.featurePhotos[0]}
                  />
                )}
            </div>
            <div className="text-info mb-24">
              <h3>{info?.summary}</h3>
              <div className="event-content">
                {/* <input type="checkbox" hidden id="more-text" /> */}
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      info?.description && info.description.startsWith("<")
                        ? info.description
                        : `<p>${info?.description || ""}</p>`,
                  }}
                ></p>
              </div>
            </div>
            <div className="donation-photo-list">
              {info?.photos?.map((item: any) => (
                <Caption src={item} key={item} />
              ))}
            </div>
          </section>
        </PreviewContainer>
        <Modal
          visible={showReport}
          onCancel={(e) => setShowReport(false)}
          onOk={(e) => {
            setShowReport(false);
            reportCrowd(info.id, reportReason).then((res) => {
              res?.success && message.success("report success!");
            });
          }}
          maskClosable
          className="modal-report"
          cancelText="CANCEL"
          okText="REPORT"
          title={<h1>Report Campaign</h1>}
        >
          <Radio.Group
            onChange={(e) => setReportReason(e.target.value)}
            value={reportReason}
          >
            <Space direction="vertical">
              <Radio key="spam" value="spam">
                Spam
              </Radio>
              <Radio key="inappropriate" value="inappropriate">
                Inappropriate
              </Radio>
              <Radio key="legal" value="legal">
                Copyright or other legal complaints
              </Radio>
            </Space>
          </Radio.Group>
        </Modal>
      </HomeLayout>
      {info?.status === "draft" && (
        <>
          <InfoModal
            {...{
              current: {
                id: info.id,
                name: info.name,
                time: info.time
              },
              history,
              show,
              setShow: () => history.push(isAdmin ? admin_router_crowd_field : `/dashboard/donation/list`),
              detailPath
            }}
          />
          <CrowdPublishPopup {...{
            current: info,
            history,
            show: showPub,
            setShow: setShowPub,
            next: () => {
              setShow(true)
            }
          }} />
        </>
      )}
    </>
  );
};

export default Preview;
