//created by Peggy on 2021/2/10
import React, { useEffect, useState } from "react";

import styled from "styled-components";

import Step from "./Step";
import Campaign from "./Campaign";

import "@/styles/dashboard/donation.less";
import { crowListLive, crowListPast } from "@/api/donation-api";
import { getSponsor } from "@/api/merbership-api";
import { localStorageGet } from "@/utils/storage";
import { UserRole } from "@/types/enum";

const IndexContainer = styled.div``;

const Index = () => {
  const [flag, setFlag] = useState(false);
  const [onList, setOnList] = useState([]);
  const [pastList, setPastList] = useState([]);
  const [live, setLive] = useState(0);
  const [past, setPast] = useState("");
  const roles = localStorageGet("roles");
  const isAdmin = roles?.includes(UserRole.ADMIN);
  const [membershipStatus, setMembershipStatus] = useState("");

  useEffect(() => {
    crowListLive().then((data: any) => {
      setOnList(data);
    });
  }, [live]);

  useEffect(() => {
    let _data = past;
    crowListPast(_data).then((data: any) => {
      setPastList(data);
    });
  }, [past]);

  useEffect(() => {
    if (pastList.length === 0 && onList.length === 0) {
      let _data = {};
      !isAdmin &&
        getSponsor(_data).then((data: any) => {
          setFlag(data.status === "completed" ? true : false);
          setMembershipStatus(data.status)
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onList, pastList]);
  return (
    <IndexContainer className="artist-donation-container admin-common-container flex column">
      {[...onList, ...pastList]?.length && (isAdmin || flag) ? (
        <Campaign
          list={{
            onGoing: onList,
            past: pastList,
          }}
          pastSearch={(val) => setPast(val)}
          liveRefresh={() => setLive(Math.random())}
        />
      ) : (
        <Step
          check={isAdmin ? true : flag}
          membershipStatus={membershipStatus}
        />
      )}
    </IndexContainer>
  );
};

export default Index;
