//created by Peggy on 2021/5/5
import React, { useEffect, useState } from "react";
import DatePicker from "@/components/DatePicker";
import dayjs from "dayjs";
import { Dropdown, Form, Input, Menu, message, Modal, Select, Table, Tag } from "antd";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { useHistory } from "react-router-dom";
import { eventList, deleteEvent } from "@/adminApp/apis/event-api";

import "./event_list.less";
import queryString from "query-string";
import { admin_router_message_to } from "@/adminApp/routers";
import { allOpt, eventTypes } from "@/types/enum";
import { capitalize } from "lodash";
import { ReactComponent as Filter } from "@/images/filter.svg";
import { useWindowSize } from "@/adminApp/models/model_admin_size";

export const eventStatus: any = {
  live: { text: "Live", color: "blue" },
  draft: { text: "Draft", color: "grey" },
  expired: { text: "Expired", color: "red" },
  closed: { text: "Closed", color: "orange" },
};

const typesOption = [allOpt]
  .concat(eventTypes)
  .map((_) => ({ label: _, value: _ }));
const statusOption = [
  allOpt,
  "live",
  "draft",
  "expired",
  "closed",
].map((_) => ({ label: capitalize(_), value: _ }));
const locationOption = [
  {
    label: 'in person event',
    value: 'offline'
  },
  {
    label: 'Online event',
    value: 'online'
  },
]

export const EventList = () => {
  const history = useHistory();

  //handler
  const handleExport = () => {
    const { page, limit, date, created, ...others } = params;
    const [start, end] = date || ["", ""];
    const [createeStart, createdEnd] = created || ["", ""];
    let _data = {
      startDate:
        (start && dayjs(start?.toString())?.format("YYYY-MM-DD")) || "",
      endDate: (end && dayjs(end?.toString())?.format("YYYY-MM-DD")) || "",
      createdFromDate: (createeStart && dayjs(createeStart?.toString())?.format("YYYY-MM-DD")) || "",
      createdToDate: (createdEnd && dayjs(createdEnd?.toString())?.format("YYYY-MM-DD")) || "",
      ...others,
    };

    window.open(
      `${process.env.REACT_APP_API_HOST
      }/event/admin/export?${queryString.stringify({
        ..._data,
        ids: selectedRows.map((item: any) => item.id).join(","),
      })}`,
      "_blank"
    );
  };

  const handleLink = (id: number) => {
    history.push("/event/" + id);
  };
  const handleDelete = (row: any) => {
    deleteEvent(row.id).then((res) => {
      res?.success && message.success("delete success");
      getList();
    });
  };

  //handler end
  const column: any[] = [
    {
      title: "EVENT NAME",
      dataIndex: "name",
      key: "name",
      width: 130,
      fixed: "left",
    },
    {
      title: "EVENT TYPE",
      dataIndex: "type",
      key: "type",
      width: 130,
      fixed: "left",
    },
    {
      title: "CREATED BY",
      key: "creater",
      width: 130,
      render: (item: any) => {
        return `${item.artist.firstName} ${item.artist.lastName}`;
      },
    },
    {
      title: "DISCIPLINE",
      dataIndex: "discipline",
      key: "discipline",
      maxWidth: 200,
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
      render: (text: string[]) => text?.join(",") || "N/A",
    },
    {
      title: "LOCATION TYPE",
      width: 150,
      dataIndex: ['location', 'type'],
      render: (locationType: string) => locationOption.find(_ => _.value === locationType)?.label || 'N/A',
    },
    {
      title: "LOCATION ZIPCODE",
      width: 170,
      dataIndex: ['location', 'zipCode'],
      render: (zipcode: string) => zipcode || 'N/A',
    },
    {
      title: "CONTACT EMAIL",
      width: 150,
      render: (item: any) => item.contactInformation.email,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      className: "artist-cell-roles",
      render: (text: string, _: any) => (
        <Tag color={eventStatus[text] ? eventStatus[text]?.color : "gray"}>
          {eventStatus[text] ? eventStatus[text]?.text : text}
        </Tag>
      ),
    },
    {
      title: "start date",
      width: 130,
      key: "createDate",
      render: (_: string, row: any) =>
        row?.time?.startDate &&
        dayjs(row?.time?.startDate).format("MM/DD/YYYY"),
    },
    {
      title: "DATE CREATED",
      dataIndex: "createDate",
      width: 130,
      key: "createDate",
      render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlayStyle={{ zIndex: 5 }}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item onClick={() => handleLink(row.id)}>
                  View event
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    history.push(
                      admin_router_message_to.replace(":to", row.artist.id)
                    )
                  }
                >
                  Message user
                </Menu.Item>
                <Menu.Item onClick={() => handleDelete(row)}>
                  Delete event
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
  });
  const [total, setTotal] = useState(0);
  const [windowHeight] = useWindowSize()
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [filterForm] = Form.useForm();
  const [filterModal, setFilterModal] = useState<any>(false);
  const handleViewFilter = () => {
    setFilterModal(true);
    filterForm.setFieldsValue(params);
  };
  const handleFilterFinish = (values: any) => {
    setSelectedRows([]);
    setTableData([]);
    setParams({
      ...params,
      ...values,
      page: 1,
      limit: 10,
    });
    setFilterModal(false);
  };

  const handleReset = () => {
    filterForm.resetFields();
    setParams({ page: 1, limit: 10, keyword: "" });
  };

  const getList = () => {
    const { date, created, ...others } = params,
      [start, end] = date || ["", ""];
    const [createeStart, createdEnd] = created || ["", ""];
    let _data = {
      startDate:
        (start && dayjs(start?.toString())?.format("YYYY-MM-DD")) || "",
      endDate: (end && dayjs(end?.toString())?.format("YYYY-MM-DD")) || "",
      createdFromDate: (createeStart && dayjs(createeStart?.toString())?.format("YYYY-MM-DD")) || "",
      createdToDate: (createdEnd && dayjs(createdEnd?.toString())?.format("YYYY-MM-DD")) || "",
      ...others,
    };
    eventList(_data)
      .then((data) => {
        setTableData(data.items);
        setTotal(data.meta.totalItems);
      })
      .catch(() => {
        setTableData([]);
        setTotal(0);
      });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);
  return (
    <div className="admin-common-container admin-events-container">
      <h1>Events</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="range-picker-wrap field-form-items">
            <span>date</span>
            <DatePicker.RangePicker
              value={params.date}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  date: e,
                });
              }}
            />
          </div>
          <div className="range-picker-wrap field-form-items">
            <span>created</span>
            <DatePicker.RangePicker
              value={params.created}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  created: e,
                });
              }}
            />
          </div>
          <div className="field-form-items">
            <span>Event Type</span>
            <Select
              options={typesOption}
              placeholder="type"
              value={params.type}
              onChange={(e) =>
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  type: e === allOpt ? undefined : e,
                })
              }
            />
          </div>
          <div className="filter-item" onClick={handleViewFilter}>
            <Filter />
          </div>
          <span className="clear-all" onClick={handleReset}>
            Clear
          </span>
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item key="artist-event-m-csv" onClick={handleExport}>Export CSV</Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Events</span>
            <Input.Search
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 420 }}
      />
      {/* </div> */}
      <Modal
        width={670}
        title="Advanced filters"
        className="common-modal admin-filter-modal admin-artist-modal"
        visible={filterModal}
        onOk={() => filterForm.submit()}
        okText="Apply"
        cancelText="cancel"
        centered
        onCancel={() => setFilterModal(false)}
        forceRender
        maskClosable={false}
      >
        <Form
          form={filterForm}
          onFinish={handleFilterFinish}
          layout="vertical"
          name="form_filter"
        >
          <h4>Date ranges</h4>
          <Form.Item label="date" name="date">
            <DatePicker.RangePicker placeholder={["From", "To"]} />
          </Form.Item>
          <Form.Item label="created" name="created">
            <DatePicker.RangePicker placeholder={["From", "To"]} />
          </Form.Item>
          <Form.Item label="Event Type" name="type">
            <Select
              options={typesOption}
              placeholder="type"
              bordered={false}
            />
          </Form.Item>

          <Form.Item label="Event Status" name="status">
            <Select
              options={statusOption}
              placeholder="status"
              bordered={false}
            />
          </Form.Item>
          <Form.Item label="location Type" name="locationType">
            <Select
              bordered={false}
              options={locationOption}
              placeholder="location Type"
            />
          </Form.Item>
          <Form.Item
            noStyle
            dependencies={['locationType']}
          >
            {
              ({ getFieldValue }) => (
                getFieldValue('locationType') === 'offline' &&
                <div className="flex">
                  <Form.Item label="Zipcode Condition" name="zipcodeCondition">
                    <Select allowClear placeholder="select..." bordered={false}>
                      <Select.Option value="one_of">Is one of</Select.Option>
                      <Select.Option value="none_of">Is none of</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Zipcode" name="zipcode">
                    <Input placeholder="Zipcode" maxLength={30} />
                  </Form.Item>
                </div>
              )
            }
          </Form.Item>

        </Form>
      </Modal>
    </div>
  );
};
