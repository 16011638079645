//created by Peggy on 2021/5/19
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Table,
} from "antd";
import dayjs from "dayjs";
import "./contribution.less";

import useConfirm from "@/components/Modal/Confirm";
import { admin_router_finance_inkind_detail } from "@/adminApp/routers";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import {
  contributionDelete,
  inkindList,
} from "@/adminApp/apis/finance-api";
import DatePicker from "@/components/DatePicker";
import queryString from "query-string";
import { shareLink } from "@/api/document-api";
import { getEnv } from "@/libs/cfg";
import { useWindowSize } from "@/adminApp/models/model_admin_size";
const { getName: getCountry } = require('country-list');
const defaultCountry = getCountry('us')

const List = () => {
  const history = useHistory();

  const { component: deleteComponent } = useConfirm(
    <div className="flex column start align-start">
      <p>Are you sure you want to permanently delete this order ?</p>
    </div>,
    {
      title: "Delete ",
      className: "suspend-confirm-modal",
      okText: "Delete",
      onOk: (id?: any) => {
        contributionDelete(id).then(() => {
          getList();
          message.success("Delete successfully");
        });
      },
    }
  );

  //handler
  const handleExport = () => {
    const { page, limit, createDate, donateDate, ...others } = params;

    window.open(
      `${process.env.REACT_APP_API_HOST
      }/contribution/inkind/export-csv?${queryString.stringify({
        ...others,
        createDate: createDate?.map((_: any) => dayjs(_).format('YYYY-MM-DD')),
        donateDate: donateDate?.map((_: any) => dayjs(_).format('YYYY-MM-DD')),
        exportId: selectedRows.map((item: any) => item.id).join(","),
        tz: dayjs.tz.guess()
      })}`,
      "_blank"
    );
  };

  const handleViewOrder = (id: any) => {
    history.push(
      admin_router_finance_inkind_detail.replace(":id", id)
    );
  };

  // const handleDelete = (row: any) => {
  //   deleteShow(row.id);
  // };

  const [loading, setLoading] = useState<boolean>(false);
  const [shareForm] = Form.useForm();
  const [showShare, setShowShare] = useState<any>(undefined);
  const handleSubmitShare = () => {
    setLoading(true);
    shareForm
      .validateFields()
      .then((values) => {
        shareLink({ ...values, url: `${getEnv("ADMIN_HOST")}/sign-in?to=` + admin_router_finance_inkind_detail.replace(":id", showShare.id), title: 'In-kind Order' })
          .then((_) => {
            _?.success && message.success("succeed.");
            onCancelShare();
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCancelShare = () => {
    shareForm.resetFields();
    setLoading(false);
    setShowShare(undefined);
  };
  //handler end
  // -search
  const column: any[] = [
    {
      title: "Artist Name",
      dataIndex: "receiverName",
      width: 130,
      fixed: "left",
    },
    {
      title: "SA Public Name",
      dataIndex: "displayName",
      fixed: "left",
      width: 150,
      render: (text: string[]) => text || "N/A",
    },
    {
      title: "Donation Description",
      dataIndex: "des",
      width: 200,
      ellipsis: true,
      render: (text: string[]) => text || "N/A",
    },
    {
      title: "Donation Date",
      width: 150,
      dataIndex: "createDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "Donation Date Entered",
      width: 210,
      dataIndex: "donateDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "Donor name",
      width: 130,
      dataIndex: ['donor', 'name'],
    },
    {
      title: "Donor Email",
      width: 130,
      ellipsis: true,
      dataIndex: ['donor', 'email'],
    },
    {
      title: "Donor Address",
      width: 150,
      ellipsis: true,
      dataIndex: ['donor', 'address'],
    },
    {
      title: "Donor City",
      width: 130,
      ellipsis: true,
      dataIndex: ['donor', 'city'],
    },
    {
      title: "Donor state",
      width: 130,
      ellipsis: true,
      dataIndex: ['donor', 'state'],
    },
    {
      title: "Donor postal code",
      width: 180,
      ellipsis: true,
      dataIndex: ['donor', 'zipCode'],
    },
    {
      title: "Donor Country",
      width: 180,
      ellipsis: true,
      render: () => defaultCountry
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item key={row.id + 'inkind-view'} onClick={() => handleViewOrder(row.id)}>
                  View order
                </Menu.Item>
                <Menu.Item key={row.id + 'inkind-shr'} onClick={() => setShowShare(row)}>
                  Share link
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const { search } = useLocation()
  const state = queryString.parse(search)
  const [text, setText] = useState("");
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
    ...{
      ...(state as any),
      createDate: (state as any).createDate?.map((_: string) => dayjs(_)),
      donateDate: (state as any).donateDate?.map((_: string) => dayjs(_)),
    },
  });
  const [total, setTotal] = useState(0);
  const [windowHeight] = useWindowSize()
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const handleReset = () => {
    setText("");
    setParams({ page: 1, limit: 10, keyword: "" });
  };

  const getList = () => {
    let { donateDate, createDate, ..._data } = params;

    inkindList({
      ..._data,
      createDate: createDate?.map((_: any) => dayjs(_).format('YYYY-MM-DD')),
      donateDate: donateDate?.map((_: any) => dayjs(_).format('YYYY-MM-DD')),
    })
      .then(({ items, meta }: any) => {
        setTableData(items);
        setTotal(meta.totalItems);
      })
      .catch(() => {
        setTotal(0);
        setTableData([]);
      });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="contribution-container admin-common-container">
      <h1>In-Kind Donations</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="field-form-items">
            <span>Donation Date</span>
            <DatePicker.RangePicker
              allowClear
              value={params.createDate}
              bordered={false}
              onChange={e => {
                setTableData([])
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  createDate: e
                })
              }}
            />
          </div>
          <div className="field-form-items">
            <span>Donation Date Entered</span>
            <DatePicker.RangePicker
              allowClear
              value={params.donateDate}
              bordered={false}
              onChange={e => {
                setTableData([])
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  donateDate: e
                })
              }}
            />
          </div>
          {/* <div className="filter-item" onClick={handleViewFilter}>
            <Filter />
          </div> */}
          <span className="clear-all" onClick={handleReset}>
            Clear
          </span>
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item key="inkind-m-csv" onClick={handleExport}>Export CSV</Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>in-kind donations</span>
            <Input.Search
              value={text}
              onChange={(e) => setText(e.target.value)}
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          showTotal: (total: any) => `Total :  ${total} `,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        showHeader={!!tableData?.length}
        sticky
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 420 }}
      />
      {deleteComponent}
      <Modal
        className="common-modal document-modal"
        title="Share Application Link"
        visible={!!showShare}
        onOk={handleSubmitShare}
        okButtonProps={{
          loading: loading,
        }}
        okText="send"
        cancelText="cancel"
        onCancel={onCancelShare}
        forceRender
        centered
      >
        <p className="des">
          You are shareing:
          <span style={{ fontWeight: "bold", paddingLeft: 6 }}>
            {showShare?.name}
          </span>
        </p>
        <Form form={shareForm} layout="vertical" name="form_inking">
          <Form.Item
            label="Reciever email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Input email",
              },
            ]}
          >
            <Input placeholder="Input email to share this link" />
          </Form.Item>
          <Form.Item
            label="Reciever name"
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Input reciever name",
              },
            ]}
          >
            <Input placeholder="Input reciever name" />
          </Form.Item>
          <Form.Item
            label="Message"
            name="msg"
            rules={[
              {
                whitespace: true,
                message: "Input message",
              },
            ]}
          >
            <Input.TextArea
              minLength={5}
              style={{ height: 100 }}
              placeholder="Input message to send out this link"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default List;
