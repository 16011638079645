import React, { useEffect, useState } from "react"
import DatePicker from "@/components/DatePicker"
import { Form, Modal, Select } from "antd"
import { useForm } from "antd/lib/form/Form"
import dayjs, { Dayjs } from "dayjs"
import { useUpdate } from "react-use"

interface Props {
    show: boolean
    onOk: (data: any) => void
    onCancel: () => void
}

type TypeF = 'this_year' | 'custom'
type TypeT = 'last_year' | 'custom'

export const DonorReportModal = ({
    show,
    onOk,
    onCancel
}: Props) => {
    const [fromType, setFromType] = useState<TypeF>('custom')
    const [toType, setToType] = useState<TypeT>('custom')
    const [form] = useForm()
    const forceUpdate = useUpdate()
    const onFinish = (value: any) => {
        const { from, to } = value
        onOk({
            from: from.map((_: Dayjs) => _.format('YYYY-MM-DD')),
            to: to.map((_: Dayjs) => _.format('YYYY-MM-DD')),
        })
    }

    useEffect(() => {
        let needUp = false
        if (fromType === 'this_year') {
            const date = dayjs()
            form.setFieldsValue({ from: [date.startOf('year'), date.endOf('year')] })
            needUp = true
        }
        if (toType === 'last_year') {
            const date = dayjs().add(-1, 'year')
            form.setFieldsValue({ to: [date.startOf('year'), date.endOf('year')] })
            needUp = true
        }

        if (needUp) {
            forceUpdate()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromType, toType])

    return <Modal
        width={670}
        title="Run a report"
        className="common-modal admin-filter-modal"
        visible={show}
        onOk={() => form.submit()}
        okText="Apply"
        cancelText="cancel"
        onCancel={onCancel}
        forceRender
        centered
        maskClosable={false}
    >
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            name="form_filter"
        >
            <Form.Item label="Date range" >
                <Select allowClear placeholder="select..." bordered={false} value={fromType} onChange={e => setFromType(e as TypeF)}>
                    <Select.Option value='this_year'>This year</Select.Option>
                    <Select.Option value='custom'>Custom</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="Current period" name="from" rules={[{ required: true, message: 'choose date' }]}>
                <DatePicker.RangePicker placeholder={["From", "To"]} disabled={fromType === 'this_year'} />
            </Form.Item>
            <Form.Item label="Compare to" >
                <Select allowClear placeholder="select..." bordered={false} value={toType} onChange={e => setToType(e as TypeT)}>
                    <Select.Option value="last_year">Last year</Select.Option>
                    <Select.Option value="custom">Custom</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="Previous period" name="to" rules={[{ required: true, message: 'choose date' }]}>
                <DatePicker.RangePicker
                    placeholder={["From", "To"]}
                    disabled={toType === 'last_year'}
                    disabledDate={d => d.isAfter(dayjs((form.getFieldValue('from') ?? [])[0]))}
                />
            </Form.Item>
        </Form>
    </Modal>
}