import { KV } from "use-reaction";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import "./application_review.less";
import { Input, Button, message, Breadcrumb, Tag, Divider, Form, Select } from "antd";
import UploadCrop from "@/components/UploadAndCrop/Cropperjs";
import { useHistory, useParams } from "react-router-dom";
import { admin_router_message_to } from "@/adminApp/routers";
import { timezoneShort, typeRequest } from "@/types/enum";
import {
  acceptGrantApply,
  addGrantApplyNote,
  deleteGrantApplyNote,
  getGrantApply,
  getGrantApplyNotes,
  reviewGrantApply,
} from "@/adminApp/apis/grant-api";
import { AdminGetOneUserInfo, userAccess } from "@/adminApp/apis/user-api";
import fileIcon from "@/images/ico_file.svg";
import rmIcon from "@/images/rm_icon.svg";
import { CloudUploadOutlined } from "@ant-design/icons";

import Modal from "antd/lib/modal/Modal";
import { LabelInputArea } from "@/components/LabelInput/LabelInput";
import { getEnv } from "@/libs/cfg";
import useConfirm from "@/components/Modal/Confirm";

type Note = {
  id: number;
  type: "link" | "text";
  content: string;
};
const statusTags = ["approved", "rejected", 'accepted'];
export const ApplicationReview = (props: KV) => {
  const { id } = useParams() as any;
  const [info, setInfo] = useState<any>({});
  const [user, setUser] = useState<any>({});
  const [noteDocs, setNoteDocs] = useState<Note[]>([]);
  const [noteTxt, setNoteTxt] = useState<Note>();
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [msg, setMsg] = useState("");

  const history = useHistory();
  const [declineReason, setDeclineReason] = useState<string>()
  const {setShow: showReject, component: rejectComp} = useConfirm(
    <div>
      <Form layout="vertical">
        <Form.Item label="Decline reason" rules={[{required: true, message:'select decline reason'}]}>
        <Select defaultActiveFirstOption onChange={e => setDeclineReason(e?.toString())}>
          <Select.Option key='reject1' value="Application/Materials Incomplete or Missing">Application/Materials Incomplete or Missing</Select.Option>
            <Select.Option key='reject2' value="Does not follow request guidelines">Does not follow request guidelines</Select.Option>
            <Select.Option key='reject3' value="Fiscal Sponsorship not required">Fiscal Sponsorship not required</Select.Option>
            <Select.Option key='reject4' value="Materials cannot be reviewed in time">Materials cannot be reviewed in time</Select.Option>
        </Select>
        </Form.Item>
      </Form>
    </div>,
    {
      title: 'Select reason for declining',
      okText: 'Decline',
      onOk:async () => {
        if(declineReason){
          let temp = 'rejected';
          if(declineReason === 'Application/Materials Incomplete or Missing'){
            temp = 'incomplete'
          }
          const res = await reviewGrantApply(parseInt(id), temp, declineReason);
          if (res?.success) {
            message.success("Declined");
            history.goBack()
          }
        }
      }
    }
  )

  const acceptApplication = async () => {
    //notify artist application is accepted
    //if it's a rush application, BE will try to charge
    const res = await acceptGrantApply(id)
    if (res?.success) {
      message.success("accepted");
      history.goBack()
    }

  }
  const approveApplication = async () => {
    const res = await reviewGrantApply(parseInt(id), "approved");
    if (res?.success) {
      message.success("approved");
      history.goBack()
    }
  };

  const sendMsg = async () => {
    setShowNoteModal(false);
    const content = msg.trim();
    if (content) {
      const res = await addGrantApplyNote(id, "text", content, noteTxt?.id);
      if (res?.id) {
        setNoteTxt(res);
      }
    }
  };
  const sendDoc = async () => {
    if (files.length) {
      for (const fl of files) {
        await addGrantApplyNote(id, "link", fl.url);
      }
      setFiles([]);
      freshNotes();
    }
  };

  const rmDoc = async (id: number) => {
    const res = await deleteGrantApplyNote(id);
    if (res?.success) {
      freshNotes();
    }
  };

  const freshNotes = () => {
    getGrantApplyNotes(id).then((res) => {
      const docs: Note[] = [];
      let txt: Note = undefined as any;
      res.items.forEach((item: Note) => {
        if (item.type === "link") {
          docs.push(item);
        } else if (!txt) {
          txt = item;
        }
      });
      setNoteDocs(docs);
      txt && setNoteTxt(txt);
      txt && setMsg(txt.content);
    });
  };
  const handleAccessToPayable = (id: number) => {
    userAccess({ id }).then((data) => {
      window.open(
        `${getEnv("WEB_HOST")}/auth/${data.token
        }?to=/dashboard/membership/payable`,
        "_blank"
      );
    });
  };
  useEffect(() => {
    getGrantApply(id).then((res) => {
      if (res?.id) {
        setInfo(res);
        AdminGetOneUserInfo(res.user_id).then((u) => {
          u && setUser(u);
        });
        // sort out notes
        freshNotes();
      }
    });
    // eslint-disable-next-line
  }, [id]);
  const status = statusTags.includes(info.status) ? info.status : "pending";
  let legalAddress = "N/A";
  if (user.signInfo?.city) {
    legalAddress = `${user.signInfo.address1} ${user.signInfo.city} ${user.signInfo.state} ${user.signInfo.zipCode}`;
  }
  const [deadlineYear, deadlineTz, deadlineTime] = info?.grant_deadlineStr?.split(',') || ['', '', '']
  return (
    <div className="admin-common-container application-review">
      <Breadcrumb separator=">">
        <Breadcrumb.Item onClick={(e) => history.goBack()}>
          Applications
        </Breadcrumb.Item>
        <Breadcrumb.Item>Application details</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex start top-bar">
        <h1 className="title">{info.contactName} </h1>
        <Tag
          className="status"
          color={status === "pending" ? "orange" : "green"}
        >
          {status}
        </Tag>
        {info.rushFeeRequired && <Tag color="red">Rush Fee Required</Tag>}
      </div>
      {/*<div className="des">*/}
      {/*  In order to publish and event, you will have to submit the content for*/}
      {/*  The Field to review. The approval process will take no more than 5*/}
      {/*  business days*/}
      {/*</div>*/}
      <div className="sub-infos">
        <h3 className="sub-title">
          {info.grant_type !== "grant" &&
            info.grant_type !== "grant_application_review"
            ? "Request"
            : "Grant"}{" "}
          information
        </h3>
        <div className="flex justify">
          <div className="key">Application Type</div>
          <div className="val">{typeRequest[info.grant_type]}</div>
        </div>
        {(info.grant_type === "grant" ||
          info.grant_type === "grant_application_review") && (
            <>
              <div className="flex justify">
                <div className="key">Grant Name</div>
                <div className="val">{info.grant_name}</div>
              </div>
              <div className="flex justify">
                <div className="key">Grant Organization</div>
                <div className="val">{info.grant_organization || "N/A"}</div>
              </div>
              {
                info.grant_type !== 'grant' &&
                <div className="flex justify">
                  <div className="key">Grant Link</div>
                  <div className="val">{info.grant_link || "N/A"}</div>
                </div>
              }
              {
                info.grant_type !== 'grant' &&
                <div className="flex justify">
                  <div className="key">Award Information</div>
                  <div className="val">{info.grant_award || "N/A"}</div>
                </div>
              }
              <div className="flex justify">
                <div className="key">Deadline</div>
                <div className="val">
                  {(info.grant_type !== "grant" && info.grant_deadline) ||
                    info.grant_deadlineType === "hard"
                    ? (dayjs(deadlineYear).format("MM/DD/YYYY ") + dayjs('20220-01-01 ' + deadlineTime).format('hh:mm A ') + timezoneShort(deadlineTz))
                    : "N/A"}
                </div>
              </div>
              {
                info.grant_type === 'grant' &&
                <div className="flex justify">
                  <div className="key">Discipline</div>
                  <div className="val">{info.grant_discipline || "N/A"}</div>
                </div>
              }
              <div className="flex justify">
                <div className="key">{info.grant_type === 'grant' ? 'Contact email' : 'Grant Organization Contact Email'}</div>
                <div className="val">{info.grant_email || "N/A"}</div>
              </div>
              <div className="flex justify">
                <div className="key">{info.grant_type === 'grant' ?'Phone number' : 'Grant Organization Phone Number'}</div>
                <div className="val">{info.grant_phone || "N/A"}</div>
              </div>
            </>
          )}

        {info.grant_type === "benefit_event" && (
          <>
            <div className="flex justify">
              <div className="key">Event Name</div>
              <div className="val">{info.grant_name || "N/A"}</div>
            </div>

            <div className="flex justify">
              <div className="key">Event Url</div>
              <div className="val">{info.grant_link}</div>
            </div>
          </>
        )}
        {info.grant_type === "solicitation_letter" && (
          <>
            <div className="flex justify">
              <div className="key">Campaign Name</div>
              <div className="val">{info.grant_name || "N/A"}</div>
            </div>
          </>
        )}
        {info.grant_type === "letter_of_inquiry" && (
          <>
            <div className="flex justify">
              <div className="key">Grant Name</div>
              <div className="val">{info.grant_name || "N/A"}</div>
            </div>
          </>
        )}
      </div>
      {info.grant_description && (
        <div className="sub-infos">
          <h3 className="sub-title">Description</h3>
          <div
            dangerouslySetInnerHTML={{
              __html:
                info?.grant_description &&
                  info.grant_description.startsWith("<")
                  ? info.grant_description
                  : `<p>${info?.grant_description || ""}</p>`,
            }}
          />
        </div>
      )}
      <div className="sub-infos">
        <h3 className="sub-title">Applicant information</h3>
        <div className="flex justify">
          <div className="key">Applicant Name</div>
          <div className="val">{info.contactName}</div>
        </div>
        <div className="flex justify">
          <div className="key">SA Company Name</div>
          <div className="val">{user.companyName}</div>
        </div>
        <div className="flex justify">
          <div className="key">Email Address</div>
          <div className="val">{user.email}</div>
        </div>
        <div className="flex justify">
          <div className="key">Address</div>
          <div className="val">{legalAddress}</div>
        </div>
        <div className="flex justify">
          <div className="key">Consultation</div>
          <div className="val">No</div>
        </div>
        <div className="flex justify">
          <div className="key">Payable</div>
          <div
            className="val"
            onClick={(e) => handleAccessToPayable(info.user_id)}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
            <a href="javascript:void">
              View here
            </a>
          </div>
        </div>
        <div className="flex justify">
          <div className="key">Artist Profile</div>
          <div className="val">
            <a
              target="_blank"
              rel="noreferrer"
              href={`${getEnv("WEB_HOST")}/profile/${(
                info.contactName || "-"
              ).replace(/[^\w]/g, "-")}/${info.user_id}`}
            >
              View here
            </a>
          </div>
        </div>
        <div className="flex justify">
          <div className="key">SA Contact</div>
          <div className="val">
            <a href={`mailto:${info.email}`}>View here</a>
          </div>
        </div>
      </div>
      {info.notes && (
        <div className="sub-infos">
          <h3 className="sub-title">Artist notes</h3>
          <div
            className="artist-notes"
            dangerouslySetInnerHTML={{
              __html:
                info?.notes && info.notes.startsWith("<")
                  ? info.notes
                  : `<p>${info?.notes || ""}</p>`,
            }}
          />
        </div>
      )}

      <div className="sub-infos">
        <h3 className="sub-title">Documents</h3>
        <div className="flex wrap documents">
          {noteDocs.map((d: Note) => (
            <div className="flex start doc">
              <img src={fileIcon} alt="file" />
              <a key={d.id} href={d.content}>
                {d.content.split("/").pop()}
              </a>
              <img src={rmIcon} alt="remove" onClick={(e) => rmDoc(d.id)} />
            </div>
          ))}
        </div>
      </div>
      <div className="sub-infos">
        <h3 className="sub-title">Admin notes</h3>
        <LabelInputArea
          className="admin-notes"
          placeholder="click to edit"
          onClick={(e) => setShowNoteModal(true)}
          label="ADMIN NOTE"
          value={noteTxt?.content}
        />
        <Divider />
        <h3 className="sub-title">Additional document</h3>
        <UploadCrop
          folder="admin_grant"
          listType="text"
          className="upload-item-wrap"
          showUploadList
          accept=".pdf,.doc,.docx,.xlsx,.csv,.png,.jpeg,.jpg"
          crop={false}
          maxCount={6}
          value={files}
          onChange={(files) => {
            setFiles(files);
          }}
        >
          <div className="children-upload">
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Please upload 1 file at a time. Strictly prohibit from uploading
              company data or other band files
            </p>
          </div>
        </UploadCrop>
        <Button hidden={!files.length} onClick={sendDoc}>
          Send Documents
        </Button>
      </div>

      <div className="flex end btns">
        <Button
          onClick={(e) =>
            history.push(admin_router_message_to.replace(":to", info.user_id))
          }
          className="btn-msg"
        >
          Send message
        </Button>
        <Button onClick={()=> showReject(true)} className="btn-reject">
          Decline
        </Button>
        <Button onClick={acceptApplication} type="primary">
          Accept
        </Button>
        <Button
          onClick={approveApplication}
          type="primary"
          className="btn-approve"
        >
          Approve
        </Button>
      </div>

      <Modal
        closable
        visible={showNoteModal}
        maskClosable={false}
        title="Admin Note"
        onCancel={(e) => setShowNoteModal(false)}
        onOk={sendMsg}
        okText="Save"
      >
        <div className="edit-note">
          <div className="des">input notes below and click Save</div>
          <Input.TextArea
            value={msg}
            style={{ height: "100px" }}
            onChange={(e) => setMsg(e.target.value)}
          />
        </div>
      </Modal>
      {rejectComp}
    </div>
  );
};
