//created by Peggy on 2021/3/29
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "@/images/logo-color.svg";
import { ReactComponent as MenuIcon } from "@/images/icon_hamburger.svg";
import { ReactComponent as MenuClose } from "@/images/icon_menu_close.svg";
import { Button, Dropdown, Menu, Divider } from "antd";
import {
  DownOutlined,
  LogoutOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { useModel } from "use-reaction";
import { loginOut, user } from "@/model/user";
import { view } from "@/model/view";
import { GoogleTranslate, GoogleTranslateTrigger, TheField } from "../GoogleTranslate";

const { SubMenu } = Menu;

interface Props {
  hideMenu?: boolean;
  hideSign?: boolean;
}

const HeaderLayout = ({ hideMenu, hideSign }: Props) => {
  const history = useHistory();
  const {
    store: { token, info, roles, progress },
    doAction,
  } = useModel(user);
  const {
    store: { isMobile },
  } = useModel(view);
  const [current, setCurrent] = useState("");
  //drawer
  const [show, setShow] = useState(false);

  const onSignOut = () => {
    doAction(loginOut);
    history.replace("/sign/in");
  };
  return (
    <header className="flex home-wrap">
      <a
        rel="noreferrer"
        target="_blank"
        className="logo-item"
        href="https://thefield.org"
      >
        <Logo />
      </a>
      {!hideMenu && (
        <Menu
          className="flex menu-item"
          mode="horizontal"
          onClick={(e: any) => setCurrent(e.key)}
          selectedKeys={[current]}
        >
          <SubMenu
            key="for_artists"
            icon={<DownOutlined />}
            title="For Artists"
          >
            <Menu.Item key="grants-and-opportunities">
              <Link to="/grants-and-opportunities">Grants & Opportunities</Link>
            </Menu.Item>
            <Menu.Item key="news">
              <a
                href="https://www.thefield.org/news-and-events/"
                target="_blank"
                rel="noopener noreferrer"
              >
                News & Events from <TheField/>
              </a>
            </Menu.Item>
            <Menu.Item key="knowledge">
              <a
                href="https://www.thefield.org/knowledge-base/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Knowledge Base
              </a>
            </Menu.Item>

            <SubMenu key="faq" title="Frequently Asked Questions">
              <Menu.Item key="faq1">
                <a
                  href="https://www.thefield.org/faq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Artist F.A.Q.
                </a>
              </Menu.Item>
              <Menu.Item key="faq2">
                <a
                  href="https://www.thefield.org/donor-faq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Donor F.A.Q.
                </a>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="events">
              <Link to="/explore-artist-community-events">
                Artist Events / Community Calendar
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="plans" icon={<DownOutlined />} title="Plans">
            <Menu.Item key="pricing">
              <a
                href="https://www.thefield.org/pricing/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pricing Overview
              </a>
            </Menu.Item>
            <Menu.Item key="artist-profile">
              <a
                href="https://www.thefield.org/free-artist-profile"
                target="_blank"
                rel="noopener noreferrer"
              >
                Free Artist Profile
              </a>
            </Menu.Item>
            <Menu.Item key="sponsorship">
              <a
                href="https://www.thefield.org/fiscal-sponsorship"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fiscal Sponsorship
              </a>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="contribute" icon={<DownOutlined />} title="Contribute">
            <Menu.Item key="contribute_to_artist">
              <Link to="/contribute-to-an-artist">Contribute to an Artist</Link>
            </Menu.Item>
            <Menu.Item key="/contribute_to_field">
              <Link to="/contribute-to-the-field">Contribute to <TheField/></Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="about" icon={<DownOutlined />} title="About">
            <Menu.Item key="history">
              <a
                href="https://www.thefield.org/mission-vision-history"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mission, Vision & History
              </a>
            </Menu.Item>
            <Menu.Item key="values">
              <a
                href="https://www.thefield.org/values/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Values
              </a>
            </Menu.Item>
            <Menu.Item key="impact-and-financials">
              <a
                href="https://www.thefield.org/impact-and-financials/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Impact & Financials
              </a>
            </Menu.Item>
            <SubMenu key="team" title="Our Team">
              <Menu.Item key="https://www.thefield.org/our-team/">
                <a
                  href="https://www.thefield.org/our-team/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Staff
                </a>
              </Menu.Item>
              <Menu.Item key="https://www.thefield.org/our-team/#boardofdirectors">
                <a
                  href="https://www.thefield.org/our-team/#boardofdirectors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Board of Directors
                </a>
              </Menu.Item>
              <Menu.Item key="https://www.thefield.org/our-team/#artistcouncil">
                <a
                  href="https://www.thefield.org/our-team/#artistcouncil"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Artist Council
                </a>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="https://www.thefield.org/funding-credits/">
              <a
                href="https://www.thefield.org/funding-credits/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Funding Credits
              </a>
            </Menu.Item>
          </SubMenu>
        </Menu>
      )}
      {(token && progress > 0 && (
        <span className="user-item">
          <GoogleTranslate/>
          <Dropdown
            placement="topRight"
            overlay={
              <Menu>
                <Menu.Item
                  key="0"
                  onClick={() => {
                    if (progress >= 4) {
                      if (roles.includes("artist")) {
                        history.push("/dashboard/income");
                      } else {
                        history.push("/dashboard/history");
                      }
                    } else {
                      history.push(
                        `/sign/${roles[0] || info.type || "artist"}/${progress ? progress - 1 : 0}`
                      );
                    }
                  }}
                  icon={<AppstoreOutlined />}
                >
                  Back to dashboard
                </Menu.Item>
                <Menu.Item
                  key="1"
                  onClick={onSignOut}
                  icon={<LogoutOutlined />}
                >
                  Sign out
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <span
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              translate="no"
            >
              <img src={info?.avatar} alt="" />
              {`${info?.firstName ?? ""} ${info?.lastName ?? ""}`}{" "}
              <DownOutlined />
            </span>
          </Dropdown>
        </span>
      )) || ( !hideSign && !isMobile &&
          <span className="user-signin">
            <GoogleTranslate/>
            <Link to={`/sign/in?to=${window.location.pathname}`}>
              <Button size="large" >
                Sign in
              </Button>
            </Link>
            <Link to="/sign/up">
              <Button size="large">Sign up</Button>
            </Link>
          </span>
      )}
      {isMobile &&
        (show ? (
          <MenuClose className="icon-menu" onClick={() => setShow(false)} />
        ) : (
          <MenuIcon className="icon-menu" onClick={() => setShow(true)} />
        ))}
      {isMobile && show && (
        <Menu
          className="mobile-header-menu"
          mode="inline"
          onClick={(e: any) => {
            if(e.key?.includes('language-')){
              GoogleTranslateTrigger(e);
              setShow(false)
              return
            }
            setCurrent(e.key)
          }}
          selectedKeys={[current]}
        >
          <SubMenu key="for_artists" title="For Artists">
            <Menu.Item key="grants-and-opportunities">
              <Link to="/grants-and-opportunities">Grants & Opportunities</Link>
            </Menu.Item>
            <Menu.Item key="news">
              <a
                href="https://www.thefield.org/news-and-events/"
                target="_blank"
                rel="noopener noreferrer"
              >
                News & Events from <TheField/>
              </a>
            </Menu.Item>
            <Menu.Item key="knowledge">
              <a
                href="https://www.thefield.org/knowledge-base/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Knowledge Base
              </a>
            </Menu.Item>
            <SubMenu key="faq" title="Frequently Asked Questions">
              <Menu.Item key="faq1">
                <a
                  href="https://www.thefield.org/faq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Artist F.A.Q.
                </a>
              </Menu.Item>
              <Menu.Item key="faq2">
                <a
                  href="https://www.thefield.org/donor-faq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Donor F.A.Q.
                </a>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="events">
              <Link to="/explore-artist-community-events">
                Artist Events / Community Calendar
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="plans" title="Plans">
            <Menu.Item key="pricing">
              <a
                href="https://www.thefield.org/pricing/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pricing Overview
              </a>
            </Menu.Item>
            <Menu.Item key="artist-profile">
              <a
                href="https://www.thefield.org/free-artist-profile"
                target="_blank"
                rel="noopener noreferrer"
              >
                Free Artist Profile
              </a>
            </Menu.Item>
            <Menu.Item key="sponsorship">
              <a
                href="https://www.thefield.org/fiscal-sponsorship"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fiscal Sponsorship
              </a>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="contribute" title="Contribute">
            <Menu.Item key="contribute_to_artist">
              <Link to="/contribute-to-an-artist">Contribute to an Artist</Link>
            </Menu.Item>
            <Menu.Item key="/contribute_to_field">
              <Link to="/contribute-to-the-field">Contribute to <TheField/></Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="about" title="About">
            <Menu.Item key="history">
              <a
                href="https://www.thefield.org/mission-vision-history"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mission, Vision & History
              </a>
            </Menu.Item>
            <Menu.Item key="values">
              <a
                href="https://www.thefield.org/values/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Values
              </a>
            </Menu.Item>
            <Menu.Item key="impact-and-financials">
              <a
                href="https://www.thefield.org/impact-and-financials/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Impact & Financials
              </a>
            </Menu.Item>
            <SubMenu key="team" title="Our Team">
              <Menu.Item key="https://www.thefield.org/our-team/">
                <a
                  href="https://www.thefield.org/our-team/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Staff
                </a>
              </Menu.Item>
              <Menu.Item key="https://www.thefield.org/our-team/#boardofdirectors">
                <a
                  href="https://www.thefield.org/our-team/#boardofdirectors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Board of Directors
                </a>
              </Menu.Item>
              <Menu.Item key="https://www.thefield.org/our-team/#artistcouncil">
                <a
                  href="https://www.thefield.org/our-team/#artistcouncil"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Artist Council
                </a>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="https://www.thefield.org/funding-credits/">
              <a
                href="https://www.thefield.org/funding-credits/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Funding Credits
              </a>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="/language"
            title="Language"
          >
            <Menu.Item key="language-en"><a href="#">English</a></Menu.Item>
            <Menu.Item key="language-es"><a href="#">Spanish</a></Menu.Item>
            <Menu.Item key="language-zh-CN"><a href="#">Chinese</a></Menu.Item>
            <Menu.Item key="language-fr"><a href="#">French</a></Menu.Item>
            <Menu.Item key="language-ja"><a href="#">Japanese</a></Menu.Item>
            <Menu.Item key="language-ar"><a href="#">Arabic</a></Menu.Item>
          </SubMenu>
          <Divider
            style={{
              borderTop: "1px solid rgba(255,255,255, 0.3)",
              margin: "0px",
            }}
          />
          {(token && progress > 0 && (
            <Menu.Item
              className="avatar-info"
              onClick={() => {
                if (progress >= 4) {
                  if (roles.includes("artist")) {
                    history.push("/dashboard/income");
                  } else {
                    history.push("/dashboard/history");
                  }
                } else {
                  history.push(
                    `/sign/${roles[0] || info.type || "artist"}/${progress ? progress - 1 : 0}}`
                  );
                }
              }}
            >
              <img src={info?.avatar} alt="" />
              {`${info?.firstName ?? ""} ${info?.lastName ?? ""}`}{" "}
            </Menu.Item>
          )) || (
            <>
              <Menu.Item key="sign-in">
                <Link to="/sign/in">Sign In</Link>
              </Menu.Item>
              <Menu.Item key="sign-up">
                <Link to="/sign/in">Sign Up</Link>
              </Menu.Item>
            </>
          )}
        </Menu>
      )}
    </header>
  );
};

export default HeaderLayout;
