//created by Peggy on 2021/3/30
import React, { useEffect, useState } from "react";

import {
  Select,
  Button,
  Dropdown,
  Menu,
  Form,
  Modal,
  message,
  Input,
} from "antd";
import DatePicker from "@/components/DatePicker";

import { CloudUploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import DocumentTable from "@/pages/dashboard/donation/InkindTable";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import styled from "styled-components";
import "@/styles/dashboard/setting.less";
import UploadCrop from "@/components/UploadAndCrop/Cropperjs";

import {
  deleteDocument,
  downloadDocument,
  getDocument,
  saveDocument,
  shareDoc,
} from "@/api/document-api";
import { SearchSelect } from "@/components/SearchSelect/SearchSelect";
import { artistList } from "@/adminApp/apis/artist-api";
import { useWindowSize } from "@/adminApp/models/model_admin_size";

const DocumentContainer = styled.div``;

// docTypes are same with defined in BE, do not modify!!
const docTypeOfGrants = [
  "Benefit Event",
  "Letter of Inquiry",
  "New Grant Request",
  "Grant Application",
  "Solicitation Letter",
];
const docTypeOther = "Other";
const documentOptions = ["Sponsorship Agreement", "Affiliation Letter"];

export const Documents = () => {
  //table
  const columns: any[] = [
    {
      title: "artist name",
      dataIndex: "artistName",
      key: "artist-name",
      width: 120,
      ellipsis: true,
    },
    {
      title: "document name",
      dataIndex: "name",
      width: 120,
      key: "name",
      ellipsis: true,
      render: (name: string) => decodeURI(name),
    },
    {
      title: "document type",
      dataIndex: "type",
      key: "type",
      width: 120,
      ellipsis: true,
    },

    {
      title: "date added",
      dataIndex: "createDate",
      width: 120,
      key: "date",
      render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "",
      width: 80,
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["hover"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item
                  onClick={() => {
                    if (row.type === "Sponsorship Agreement") {
                      downloadBefore(row.id);
                    } else {
                      window.open(row.url);
                    }
                  }}
                >
                  Download
                </Menu.Item>
                {row.type !== "Sponsorship Agreement" && (
                  <Menu.Item onClick={() => setShowShare(row)}>Share</Menu.Item>
                )}
                <Menu.Item onClick={() => deleteDoc(row)}>Delete</Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    pageSize: 20,
  });
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [windowHeight] = useWindowSize()

  const getList = () => {
    const { pageSize: limit, ...others } = params;
    let _data = {
      ...others,
      limit,
    };
    getDocument(_data).then((data) => {
      setTableData(data.items);
      setTotal(data.total);
    });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  //letter
  const [shareForm] = Form.useForm();
  const [showShare, setShowShare] = useState<any>(undefined);
  const handleSubmitShare = () => {
    setLoading(true);
    shareForm
      .validateFields()
      .then((values) => {
        shareDoc({ ...values, url: showShare.url })
          .then((_) => {
            _?.success && message.success("succeed.");
            onCancelShare();
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCancelShare = () => {
    shareForm.resetFields();
    setLoading(false);
    setShowShare(undefined);
  };

  const deleteDoc = (row: any) => {
    setLoading(true);
    deleteDocument({ id: row.id, docType: row.docType })
      .then((_) => {
        _?.success && message.success("succeed.");
        getList();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  //search
  const handleSearch = (keyword: string) => {
    let _data = {
      keyword,
    };
    return artistList(_data).then((data) => {
      return data.items.map((u: any) => ({
        value: u.userId,
        label: `${u.firstName} ${u.lastName}`,
        key: u.id,
      }));
      // setOptions(options)
    });
  };

  //download
  const downloadBefore = (id: string) => {
    downloadDocument({
      id,
    }).then((data) => {
      window.open(data.url);
    });
  };
  //Modal
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        const { document, artist, ...others } = values;
        saveDocument({
          ...others,
          userId: artist.value,
          url: document.map((item: any) => item.url).join(","),
          sendContractNote: others.type === 'Sponsorship Agreement'
        })
          .then((data) => {
            message.success("Document added successfully.");
            onCancel();
            getList();
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCancel = () => {
    form.resetFields();
    setLoading(false);
    setShow(false);
  };
  return (
    <DocumentContainer className="admin-common-container document-container">
      <h1>Documents</h1>
      <div className="search-wrap flex">
        <div className="flex">
          <div className="range-picker-wrap field-form-items">
            <span>date</span>
            <DatePicker.RangePicker
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
              onChange={(e) => {
                const [startDate, endDate] = e || [null, null];
                setParams({
                  ...params,
                  startDate,
                  endDate,
                });
              }}
            />
          </div>
          <div className="field-form-items">
            <span>document type</span>
            <Select
              allowClear
              bordered={false}
              placeholder="Please select "
              mode="multiple"
              onChange={(e) => {
                setParams({
                  ...params,
                  type: e,
                });
              }}
            >
              {[...documentOptions, ...docTypeOfGrants, docTypeOther].map(
                (item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                )
              )}
            </Select>
          </div>
        </div>
        <div className="flex">
          <Button type="primary" onClick={() => setShow(true)}>
            upload document
          </Button>
        </div>
      </div>
      {/* <div className="table-item"> */}
        <DocumentTable
          title="Documents"
          className="table-container"
          tableData={tableData}
          columns={columns}
          rowKey={(row, idx)=> row.id + row.docType}
          onSearch={(e) =>
            setParams({
              ...params,
              keyword: e,
            })
          }
          scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 420 }}
          // scroll={{ scrollToFirstRowOnChange: true, x: "max-content", ...(tableData.length ? { y: 'calc(100vh - 430px)' } : {}) }}
          pagination={{
            total,
            current: params.page,
            pageSize: params.pageSize,
            onChange: (page: number, pageSize: number) => {
              setParams({
                ...params,
                ...{
                  page,
                  pageSize,
                },
              });
            },
          }}
        />
      {/* </div> */}
      <Modal
        className="common-modal document-modal"
        title="Share document"
        visible={!!showShare}
        onOk={handleSubmitShare}
        okButtonProps={{
          loading: loading,
        }}
        okText="save"
        cancelText="cancel"
        onCancel={onCancelShare}
        forceRender
        centered
      >
        <p className="des">
          You are shareing:
          <span style={{ fontWeight: "bold", paddingLeft: 6 }}>
            {showShare?.name}
          </span>
        </p>
        <Form form={shareForm} layout="vertical" name="form_inking">
          <Form.Item
            label="Reciever email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Input email",
              },
            ]}
          >
            <Input placeholder="Input email to share this document" />
          </Form.Item>
          <Form.Item
            label="Reciever name"
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Input reciever name",
              },
            ]}
          >
            <Input placeholder="Input reciever name" />
          </Form.Item>
          <Form.Item
            label="Message"
            name="msg"
            rules={[
              {
                whitespace: true,
                message: "Input message",
              },
            ]}
          >
            <Input.TextArea
              minLength={5}
              style={{ height: 100 }}
              placeholder="Input message to send out this document"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="common-modal document-modal"
        title="Upload document"
        visible={show}
        onOk={handleSubmit}
        okButtonProps={{
          loading: loading,
        }}
        okText="upload"
        cancelText="cancel"
        onCancel={onCancel}
        forceRender
        centered
      >
        <h3>Document information</h3>
        <Form form={form} layout="vertical" name="form_inking">
          <Form.Item
            label="Artist"
            name="artist"
            className="select-container"
            rules={[
              {
                required: true,
                message: "Select one Artist",
              },
            ]}
          >
            <SearchSelect
              placeholder="search artist"
              fetchOptions={handleSearch}
            // onChange={v => v && form.setFieldsValue({ artist: v })}
            />
          </Form.Item>
          <Form.Item
            label="document type"
            name="type"
            className="select-container"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Document Type",
              },
            ]}
          >
            <Select placeholder="Select">
              {[...documentOptions, docTypeOther].map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="document"
            noStyle
            rules={[
              {
                required: true,
                type: "array",
                message: "document",
              },
            ]}
          >
            <UploadCrop
              folder="document"
              listType="text"
              className="upload-item-wrap"
              showUploadList
              accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg"
              maxCount={1}
              crop={false}
            >
              <div className="children-upload">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Please upload 1 file at a time. Strictly prohibit from
                  uploading company data or other band files
                </p>
              </div>
            </UploadCrop>
          </Form.Item>
        </Form>
      </Modal>
    </DocumentContainer>
  );
};
