//created by Peggy on 2021/7/8
import React, { useEffect, useState } from "react";
import { Input } from "antd";

interface Props {
  value?: any;
  placeholder?: string;
  maxLength?: number;
  onChange?: (e: any) => void;
  className?: string;
  disabled?: boolean;
}

const AmountInput = ({
  value = "",
  maxLength = 50,
  placeholder = "Input",
  onChange = () => {},
  disabled
}: Props) => {
  const [state, setState] = useState<any>(value);
  useEffect(() => {
    setState(value);
  }, [value]);
  return (
    <Input
      prefix="$"
      style={{ width: "100%" }}
      value={state}
      allowClear
      disabled={disabled}
      maxLength={maxLength}
      placeholder={placeholder}
      onChange={(e: any) => {
        setState(e?.target?.value?.replace(/[^\d.]+/g, ""));
      }}
      onFocus={(e) => setState(e?.target?.value?.replace(/,/g, ""))}
      onBlur={(e) => {
        let _val = e?.target?.value
          ? Number(e?.target?.value?.replace(/,/g, "")).toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "";
        setState(_val);
        onChange(_val.replace(/,/g, ''));
      }}
    />
  );
};

export default AmountInput;
