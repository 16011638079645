//created by Peggy on 2021/2/10
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Steps, Button } from "antd";
import Modal from "@/components/Modal/Info";
import icon_campaign from "@/images/icons/icon_campaign.svg";

import styled from "styled-components";
import useConfirm from "@/components/Modal/Confirm";
import { localStorageGet } from "@/utils/storage";
import { UserRole } from "@/types/enum";
import { admin_router_crowd_field_edit } from "@/adminApp/routers";
import {
  getPopupButtonByMembershipStatus,
  getPopupDescByMembershipStatus,
  getPopupTitleByMembershipStatus,
} from "@/utils/common";
import { TheField } from "@/components/GoogleTranslate";
const StepContainer = styled.div``;

interface Props {
  check?: boolean;
  membershipStatus?: string;
}

const Step = ({ check = false, membershipStatus = "" }: Props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const roles = localStorageGet("roles");
  const isAdmin = roles?.includes(UserRole.ADMIN);

  const { setShow: showCreatePopup, component: createCrowdPopup } = useConfirm(
    <div className="create-crowd-popup">
      Before you begin, have you:
      <br />• Thought about your timing / budget / audience? We recommend
      reading our article on&nbsp;
      <a
        href="https://www.thefield.org/resource-crowdfunding-campaign/"
        target="_blank" rel="noreferrer"
      >
        how to run a successful crowdfunding campaign.
      </a>
      <br />• Read how crowdfunding works on <TheField/>? If not,{" "}
      <a href="https://www.thefield.org/crowdfunding/" target="_blank" rel="noreferrer">
        please review our guidelines here.
      </a>
      <br />
      • Please note: You are NOT permitted to offer rewards in the form of goods
      or services in exchange for donations. This is in order for crowdfunding
      contributions to be fully tax-deductible. Campaigns offering goods or
      services as rewards will be flagged for removal.
      <br />
    </div>,
    {
      title: "Creating crowdfunding campaign",
      okText: "Continue",
      onOk: () =>
        history.push(
          isAdmin
            ? admin_router_crowd_field_edit.split(":")[0]
            : "/dashboard/donation/create"
        ),
    }
  );

  const handleCheck = () => {
    if (check) {
      // history.push("/dashboard/donation/create");
      showCreatePopup(true);
    } else {
      setShow(true);
    }
  };
  useEffect(() => {}, []);
  return (
    <StepContainer className="step-container flex column">
      <div className="step-wrap flex column start">
        <img src={icon_campaign} alt="campaign_icon" />
        <h2>You don't currently have a crowdfunding campaign</h2>
        <p>
          Create a crowdfunding campaign to raise funds for your next project in
          3 steps.
        </p>
        <Steps
          current={0}
          progressDot={(dot: any, { index }: any) => (
            <span className="border-item">{index + 1}</span>
          )}
        >
          <Steps.Step
            title="Draft"
            description="Create and edit your crowdfunding campaign"
          />
          <Steps.Step
            title="Preview"
            description="See what your campaign will look like to potential donors"
          />
          <Steps.Step
            title="Publish"
            description="Publish your campaign and start raising funds!"
          />
        </Steps>
        <Button type="primary" onClick={handleCheck}>
          create now
        </Button>
        <a href="https://www.thefield.org/crowdfunding/" target="_blank" rel="noreferrer">
          Learn more
        </a>
      </div>
      {createCrowdPopup}
      <Modal
        visible={show}
        width={420}
        footer={[
          <Button key="back" onClick={() => setShow(false)}>
            LATER
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push("/dashboard/membership/fiscal")}
          >
            {getPopupButtonByMembershipStatus(membershipStatus)}
          </Button>,
        ]}
      >
        <h2>
          {getPopupTitleByMembershipStatus(
            membershipStatus,
            "One more step to set up your crowdfunding campaign"
          )}
        </h2>
        <p>
          {getPopupDescByMembershipStatus(
            membershipStatus,
            "In order to set up a crowdfunding campaign, you will have to sign up for Fiscal Sponsorship."
          )}
        </p>
      </Modal>
    </StepContainer>
  );
};

export default Step;
