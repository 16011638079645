import {
  getConsultationBySalt,
  updateConsultationStatusBySalt,
} from "@/adminApp/apis/consultation-api";
import { ReactComponent as Logo } from "@/images/logo-text.svg";
import { useUrlState } from "@/utils/util";
import { useRequest } from "ahooks";
import { Button, message, Form, Select, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import {
  ConsultationStatus,
  declineReasons,
  formatDateTimeAndTimezoneET,
} from "../scheduling/shared";

const home = "https://thefield.org";

const ConsultationReview = () => {
  const [state] = useUrlState({ id: "", action: "", key: "" });
  const [finished, setFinished] = useState(false);
  const [form] = useForm();
  const { data, loading } = useRequest(
    () => getConsultationBySalt(state.id, state.key),
    {
      refreshDeps: [state.id, state.key],
      ready: !!state.id && !!state.key,
    }
  );
  const updateStatusRequest = useRequest(updateConsultationStatusBySalt, {
    manual: true,
  });
  const artistName = data?.artistUser?.profile?.fullName ?? "";
  const dateString = formatDateTimeAndTimezoneET(data?.startTime)?.join?.(" ");
  const onAccept = async () => {
    try {
      await updateStatusRequest.runAsync(
        {
          id: data.id,
          status: ConsultationStatus.CONFIRMED,
        },
        state.key
      );
      message.success("Consultation accepted successfully!");
      setFinished(true);
      navigateToHome();
    } catch (error: any) {
      message.error(error?.message || error?.statusText || error);
    }
  };
  const onDecline = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const template = declineReasons.find(
      (item) => item.value === values.reason
    )?.template;
    try {
      await updateStatusRequest.runAsync(
        {
          id: data.id,
          status: ConsultationStatus.DECLINED,
          template,
          ...values,
        },
        state.key
      );
      message.success("Consultation declined successfully!");
      setFinished(true);
      navigateToHome();
    } catch (error: any) {
      message.error(error?.message || error?.statusText || error);
    }
  };
  const navigateToHome = () => {
    window.location.href = home;
  };
  const noData = !loading && !data;
  return (
    <div className="w-full flex flex-col h-screen bg-[#F1F1F6] font-roboto">
      <a className="p-6 shadow self-start bg-white" href={home}>
        <Logo />
      </a>
      {noData && (
        <div className="flex-auto w-screen flex items-center justify-center">
          <h1 className="mb-20">Url invalid or expired</h1>
        </div>
      )}
      {state.action === "accept" && !noData && (
        <div className="flex-auto flex flex-col items-center justify-center">
          <div className="text-[40px] font-[900] leading-[50px] mb-[71px]">
            Accept Consultation Request
          </div>
          <div className="bg-[white] flex flex-col items-start shadow-md p-12 mb-20 min-h-[304px] w-[590px] max-w-[100vw]">
            <div className="text-[#304659] text-[24px] leading-[38px] font-bold">
              You are accepting the following request:
            </div>
            <div className="mt-5">
              {artistName} requests a consultation on {dateString}
            </div>
            <div className="flex mt-auto">
              <Button
                type="primary"
                onClick={onAccept}
                disabled={finished}
                loading={updateStatusRequest.loading}
              >
                Yes, I'm Sure
              </Button>
              <Button
                className="ml-4"
                disabled={finished}
                onClick={navigateToHome}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      {state.action === "decline" && !noData && (
        <div className="flex-auto flex flex-col items-center justify-center">
          <div className="text-[40px] font-[900] leading-[50px] mb-[71px]">
            Decline Consultation Request
          </div>
          <div className="bg-[white] flex flex-col items-start shadow-md p-12 mb-20 min-h-[304px] w-[590px] max-w-[100vw]">
            <div className="text-[#304659] text-[24px] leading-[38px] font-bold">
              You are declining the following request:
            </div>
            <div className="mt-5">
              {artistName} requests a consultation on {dateString}
            </div>
            <Form layout="vertical" form={form} className="w-full mt-[60px]">
              <Form.Item
                label="REASON"
                name="reason"
                rules={[
                  {
                    required: true,
                    message: "Please select a reason",
                  },
                ]}
              >
                <Select
                  maxLength={100}
                  placeholder="Select a reason"
                  options={declineReasons}
                />
              </Form.Item>
              <Form.Item label="NOTE" name="note">
                <Input maxLength={1000} placeholder="Type something" />
              </Form.Item>
            </Form>
            <div className="flex mt-auto">
              <Button
                type="primary"
                onClick={onDecline}
                disabled={finished}
                loading={updateStatusRequest.loading}
              >
                Yes, I'm Sure
              </Button>
              <Button
                disabled={finished}
                className="ml-4"
                onClick={navigateToHome}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      {loading && <Spin className="global-spin-container" />}
    </div>
  );
};

export default ConsultationReview;
