//created by Peggy on 2021/5/5
import React, { useEffect, useState } from "react";
import DatePicker from "@/components/DatePicker";
import dayjs from "dayjs";
import {
  Dropdown,
  Input,
  Menu,
  message,
  Select,
  Table,
  Tag,
  Button,
  Form,
  Modal,
  InputNumber,
} from "antd";

import Step from "./step";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { useHistory } from "react-router-dom";
import {
  eventList,
  deleteEvent,
  eventOrderDuplicate,
  eventEditRegNum,
} from "@/adminApp/apis/event-api";

import "./list.less";
import queryString from "query-string";
import {
  // admin_router_message_to,
  // admin_router_event_field_registration,
  admin_router_event_field_create,
} from "@/adminApp/routers";
import { allOpt } from "@/types/enum";
import { capitalize } from "lodash";
import { getEnv } from "@/libs/cfg";
import { useWindowSize } from "@/adminApp/models/model_admin_size";

export const eventStatus: any = {
  live: { text: "Live", color: "blue" },
  draft: { text: "Draft", color: "grey" },
  expired: { text: "Expired", color: "red" },
  closed: { text: "Closed", color: "orange" },
};

const typesOption = [
  { label: "Development Events", value: "Development Events" },
  { label: "Professional Development", value: "Professional Development" },
  { label: "Life Preparedness", value: "Life Preparedness" },
  { label: "Thought Leadership", value: "Thought Leadership" },
  { label: "Third Party Event", value: "Third Party Event" },
];
const statusOption = [
  allOpt,
  "live",
  "draft",
  "expired",
  "closed",
].map((_) => ({ label: capitalize(_), value: _ }));

export const EventFieldList = () => {
  const history = useHistory();
  const [editReg, setEditReg] = useState<any>()
  const [regNum, setRegNum] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false);
  //handler
  const handleExport = () => {
    const { page, limit, date = ["", ""], ...others } = params,
      [startDate, endDate] = date;
    window.open(
      `${process.env.REACT_APP_API_HOST
      }/event/admin/export?${queryString.stringify({
        startDate,
        endDate,
        ...others,
        ids: selectedRows.map((item: any) => item.id).join(","),
      })}`,
      "_blank"
    );
  };

  const handleDuplicate = (id: number) => {
    eventOrderDuplicate({ id }).then(() => {
      message.success("Duplicate success");
      getList();
    });
  };

  const handleLink = (rows: any) => {
    if (rows.status === "draft") {
      history.push(`/event/${rows.id}/preview`);
    } else {
      window.open(
        `${getEnv("WEB_HOST")}/event/${rows.id}/${rows.name.replace(
          /[^\w]/g,
          "-"
        )}`,
        "_blank"
      );
    }
  };

  const handleDelete = (row: any) => {
    deleteEvent(row.id).then((res) => {
      res?.success && message.success("Delete success");
      getList();
    });
  };

  //handler end
  const column: any[] = [
    {
      title: "EVENT NAME",
      dataIndex: "name",
      fixed: "left",
    },
    {
      title: "participants",
      dataIndex: "participants",
      width: 130,
      align: 'center',
      render: (text: any, row: any) => row.pricing?.purchased,
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
      width: 130,
      align: 'center',
      render: (text: any, row: any) =>
        row?.pricing?.model !== "custom_tier"
          ? row?.pricing?.inventory > -1
            ? row?.pricing?.inventory
            : "Unlimited"
          : row?.pricing?.customTierPricing?.reduce(
            (sum: number, item: any) => sum + item?.inventory || 0,
            0
          ),
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      width: 100,
      align: 'center',
      render: (text: any, row: any) =>
        text
          ? Number(text).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })
          : "N/A",
    },
    {
      title: "Program Type",
      dataIndex: "type",
      width: 140,
      render: (text: any) => text,
    },
    {
      title: "Event Presenter",
      dataIndex: "presenter",
      maxWidth: 200,
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
      render: (text: string) => text || "N/A",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      className: "artist-cell-roles",
      render: (text: string, _: any) => (
        <Tag color={eventStatus[text] ? eventStatus[text]?.color : "gray"}>
          {eventStatus[text] ? eventStatus[text]?.text : text}
        </Tag>
      ),
    },
    {
      title: "ACCESS",
      dataIndex: "private",
      className: "artist-cell-roles",
      render: (text: boolean) => (
        <Tag color={ text? 'gray' : "blue"}>
          {text ? 'Private' : 'Public'}
        </Tag>
      ),
    },
    {
      title: "DATE CREATED",
      dataIndex: "createDate",
      width: 130,
      render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "event date",
      width: 120,
      dataIndex: "eventDate",
      render: (text: string, row: any) =>
        (row?.time?.startDate &&
          dayjs(row?.time?.startDate).format("MM/DD/YYYY")) ||
        "N/A",
    },
    {
      title: "open date",
      width: 120,
      dataIndex: ["openDate", "startDate"],
      render: (text: string, row: any) => text ? dayjs(text).format("MM/DD/YYYY") : "N/A",
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlayStyle={{ zIndex: 5 }}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item key={row.id + 'd'} onClick={() => handleLink(row)}>
                  View event detail
                </Menu.Item>
                {row.type !== 'Third Party Event' && <Menu.Item key={row.id + 'er'}
                  onClick={() =>
                    history.push(
                      `/program/field/${row.id}/registration-${row.name}`
                    )
                  }
                >
                  View registration
                </Menu.Item>}
                {row.type === 'Third Party Event' && <Menu.Item key={row.id + 'r'} onClick={() => {
                  setRegNum(row.pricing?.purchased)
                  setEditReg(row)
                }}>
                  Edit registration
                </Menu.Item>}
                <Menu.Item key={row.id + 'e'}
                  onClick={() => history.push("/program/field/" + row.id)}
                >
                  Edit event
                </Menu.Item>
                <Menu.Item key={row.id + 'dp'} onClick={() => handleDuplicate(row.id)}>
                  Duplicate event
                </Menu.Item>
                <Menu.Item key={row.id + 'del'} onClick={() => handleDelete(row)}>Delete</Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
    category: "the_field",
  });
  const [total, setTotal] = useState(0);
  const [windowHeight] = useWindowSize()
  const [init, SetInit] = useState();
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const getList = () => {
    const { date, ...others } = params,
      [start, end] = date || ["", ""];
    let _data = {
      startDate:
        (start && dayjs(start?.toString())?.format("YYYY-MM-DD")) || "",
      endDate: (end && dayjs(end?.toString())?.format("YYYY-MM-DD")) || "",
      ...others,
    };
    eventList(_data)
      .then((data) => {
        setTableData(data.items);
        setTotal(data.meta.totalItems);
        !init && SetInit(data.meta.totalItems);
      })
      .catch(() => {
        setTableData([]);
        setTotal(0);
      });
  };

  const handleSubmitEditReg = async () => {
    if (regNum) {
      setLoading(true)
      await eventEditRegNum({ id: editReg.id, num: regNum })
      setLoading(false)
      setEditReg(undefined)
      getList()
    }
  }
  const onCancelEditReg = () => {
    setLoading(false)
    setEditReg(undefined)
    setRegNum(undefined)
  }
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);
  return init === 0 ? (
    <Step />
  ) : (
    <div className="admin-common-container admin-events-container">
      <h1 className="flex">
        The Field Events{" "}
        <Button
          type="primary"
          onClick={() =>
            history.push(
              admin_router_event_field_create.replace(/:id\(\\d\+\)/g, "0")
            )
          }
        >
          create A Program
        </Button>
      </h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="range-picker-wrap field-form-items">
            <span>event date</span>
            <DatePicker.RangePicker
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  date: e,
                });
              }}
            />
          </div>
          <div className="range-picker-wrap field-form-items">
            <span>Deadline</span>
            <DatePicker
              value={params.deadline}
              onChange={(e) => {
                const deadline = e || "";
                setTableData([]);
                setParams({
                  ...params,
                  deadline,
                });
              }}
            />
          </div>
          <div className="field-form-items">
            <span>Event Type</span>
            <Select
              options={typesOption}
              placeholder="type"
              allowClear
              onChange={(e) =>
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  type: e === allOpt ? undefined : e,
                })
              }
            />
          </div>
          <div className="field-form-items">
            <span>Event Status</span>
            <Select
              options={statusOption}
              placeholder="status"
              onChange={(e) =>
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  status: e === allOpt ? undefined : e,
                })
              }
            />
          </div>
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item key={'exp'} onClick={handleExport}>Export CSV</Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Events</span>
            <Input.Search
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 420 }}
      />
      {/* </div> */}
      <Modal
        className="common-modal document-modal"
        title="Edit Registration"
        visible={!!editReg}
        onOk={handleSubmitEditReg}
        okButtonProps={{
          loading: loading,
        }}
        okText="save"
        cancelText="cancel"
        centered
        onCancel={onCancelEditReg}
        forceRender
      >
        <p className="des">
          You are editing number of registration to the system.
          {/* <span style={{ fontWeight: "bold", paddingLeft: 6 }}>
            {editReg?.name}
          </span> */}
        </p>
        <Form layout="vertical" name="form_inking">
          <Form.Item
            label="Number oF Registration"
            rules={[
              {
                required: true,
                type: "number",
                message: "Enter Number Of Registration",
              },
            ]}

          >
            <InputNumber style={{ width: '100%' }} bordered={false} value={regNum} min={0} onChange={e => setRegNum(e)} placeholder="Enter Number Of Registration" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
