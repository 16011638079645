import {
  deleteGrantApply,
  searchGrantApplications,
} from "@/adminApp/apis/grant-api";
import {
  admin_router_application_review,
  admin_router_message_to,
} from "@/adminApp/routers";
import {
  LabelDateRangePicker,
  LabelSelect,
} from "@/components/LabelInput/LabelInput";
import {allOpt, typeRequest, typeShortRequest} from "@/types/enum";
import {
  Badge,
  Divider,
  Dropdown,
  Input,
  Menu,
  message,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { KV } from "use-reaction";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import queryString from "query-string";

import "./application_list.less";
import { useWindowSize } from "@/adminApp/models/model_admin_size";

const emptyRange = [undefined, undefined];
const initFilters = {
  keyword: undefined,
  deadline: emptyRange,
  createDate: emptyRange,
  status: undefined,
  page: 1,
  limit: 10,
};
const dateFormat = "MM/DD/YYYY";
const statusOpts = [
  {
    label: allOpt,
    value: allOpt,
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Accepted",
    value: "accepted",
  },
  {
    label: "Declined",
    value: "rejected",
  },
  {
    label: "Approved",
    value: "approved",
  },
];
const grantTypeOpts = Object.entries(typeRequest).map(([value, label]) => ({ label, value }))
const statusDisplay = {
  applied: {
    lbl: "Pending",
    color: "orange",
  },
  rush_pending: {
    lbl: "Rush fee required",
    color: "red",
  },
  incomplete: {
    lbl: "Incomplete",
    color: "orange",
  },
  approved: {
    lbl: "Approved",
    color: "green",
  },
  rejected: {
    lbl: "Declined",
    color: "red",
  },
  failed: {
    lbl: "Failed",
    color: "red",
  },
  accepted: {
    lbl: "Accepted",
    color: "blue",
  },
};

export const ApplicationList = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<KV>(initFilters);
  const [list, setList] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [pending, setPending] = useState({grant_application_review:0,benefit_event:0,solicitation_letter:0,letter_of_inquiry:0,grant:0});
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [windowHeight] = useWindowSize();

  const columns: any[] = [
    {
      title: "Grant name",
      dataIndex: "grant_name",
      fixed: "left",
      width: 130,
      maxWidth: 230,
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
    },
    {
      title: "Request types",
      width: 230,
      dataIndex: "grant_type",
      fixed: "left",
      render: (name: string) => typeShortRequest[name],
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
    },
    {
      title: "SA name",
      width: 130,
      dataIndex: "user_legal_name",
      fixed: "left",
      render: (name: number) => <div className="legal-name">{name}</div>,
    },
    {
      title: "Email Adress",
      dataIndex: "email",
      render: (email: string) => <div className="email">{email}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: keyof typeof statusDisplay, item: any) => (
        <Tag
          color={statusDisplay[status]?.color || "orange"}
          className="status"
        >
          {status === 'rush_pending'? (item.rush_card_filled? 'Pending' : statusDisplay[status]?.lbl) : statusDisplay[status]?.lbl || "Pending"}
        </Tag>
      ),
    },
    {
      title: "Due Date",
      width: 120,
      dataIndex: "grant_deadline",
      render: (deadline: string) => (
        <div className="deadline">
          {deadline ? dayjs(deadline).format(dateFormat) : "N/A"}
        </div>
      ),
    },
    {
      title: "Date Created",
      width: 130,
      dataIndex: "createDate",
      render: (date: string) => (
        <div className="date-created">{dayjs(date).format(dateFormat)}</div>
      ),
    },
    {
      title: "Date Approved",
      width: 150,
      dataIndex: "approveDate",
      render: (date: string) => (
        <div className="date-created">
          {date ? dayjs(date).format(dateFormat) : "N/A"}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      fixed: "right",
      width: 90,
      render: (id: number, item: any) => (
        <Tooltip
          className="action-tool"
          placement="bottomLeft"
          overlay={
            <Menu onClick={({ key }) => menuAction[key](id, item)}>
              <Menu.Item key="view_detail">View details</Menu.Item>
              <Menu.Item key="message">Message Artist</Menu.Item>
              <Menu.Item key="delete">Delete Application</Menu.Item>
            </Menu>
          }
        >
          <div className="action">
            <FilterMore />
          </div>
        </Tooltip>
      ),
    },
  ];

  const updateFilter = (key: string, val: any) => {
    setFilters({ ...filters, [key]: val, page: 1 });
  };
  const searchList = async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    const res = await searchGrantApplications({
      ...filters,
    });
    setLoading(false);
    setList((res.items || []).map((g: any) => ({ ...g, key: g.id })));
    setTotal(res.total)
    setPending(res.pending);
  };

  const exportCsv = () => {
    const { page, limit, ...others } = filters;

    window.open(
      `${process.env.REACT_APP_API_HOST
      }/grant/applications/export-csv?${queryString.stringify({
        ...others,
        ids: selectedRows.map((item: any) => item.id).join(","),
        tz: dayjs.tz.guess()
      })}`,
      "_blank"
    );
  };

  const menuAction = useMemo(() => {
    return {
      view_detail: (id: number) => {
        history.push(admin_router_application_review.replace(":id", id + ""));
      },
      message: async (id: number, item: any) => {
        history.push(admin_router_message_to.replace(":to", item.user_id));
      },
      delete: async (id: number) => {
        setLoading(true);
        const res = await deleteGrantApply(id);
        if (res?.success) {
          message.success("delete success.");
          searchList();
        }
        setLoading(false);
      },
    };
    // eslint-disable-next-line
  }, []) as any;

  useEffect(() => {
    searchList();
    // eslint-disable-next-line
  }, [filters]);
  return (
    <div className="admin-common-container admin-application-list">
      <div className="topbar flex start">
        <h2 className="title">Applications</h2>
      </div>
      <div className="flex wrap between filters">
        <LabelDateRangePicker
          label="Due Date"
          className="filter-item date-range deadline"
          format={dateFormat}
          value={filters.deadline}
          onChange={(e) => updateFilter("deadline", e || emptyRange)}
        />
        <LabelDateRangePicker
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
          label="Submit Date"
          className="filter-item date-range datecreate"
          format={dateFormat}
          value={filters.createDate}
          onChange={(e) => updateFilter("createDate", e || emptyRange)}
        />
        <LabelSelect
          className="filter-item"
          label="Status"
          placeholder="Select Status"
          value={filters.status}
          onChange={(e) => updateFilter("status", e)}
          options={statusOpts}
        />
        <LabelSelect
          className="filter-item"
          label="Request Type"
          placeholder="Request Type"
          value={filters.grantType}
          mode="multiple"
          maxTagCount='responsive'
          onChange={(e) => updateFilter("grantType", e)}
          options={grantTypeOpts}
        />
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={exportCsv}>Export CSV</Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      <Badge.Ribbon text="PENDING APPLICATIONS" placement="start">
      <div className="flex between statistics">
        <div className="num-item">
          <div className="num">{pending.grant_application_review}</div>
          <div className="num-title">Grant Proposal/Application</div>
        </div>
        <Divider type="vertical" />
        <div className="num-item">
          <div className="num">{pending.grant}</div>
          <div className="num-title">The Field Grant Listing</div>
        </div>
        <Divider type="vertical" />
        <div className="num-item">
          <div className="num">{pending.benefit_event}</div>
          <div className="num-title">Benefit Event Invitation</div>
        </div>
        <Divider type="vertical" />
        <div className="num-item">
          <div className="num">{pending.solicitation_letter}</div>
          <div className="num-title">Solicitation/Fundraising Letter</div>
        </div>
        <Divider type="vertical" />
        <div className="num-item">
          <div className="num">{pending.letter_of_inquiry}</div>
          <div className="num-title">Letter of Inquiry</div>
        </div>
        <Divider type="vertical" className="total-divider"/>
        <div className="num-item">
          <div className="num">{pending.letter_of_inquiry + pending.solicitation_letter + pending.benefit_event + pending.grant + pending.grant_application_review}</div>
          <div className="num-title total-title">Total Pending</div>
        </div>
      </div>
      </Badge.Ribbon>

      {/* <div className="table-container"> */}
      <Table
        className="table-container list"
        title={() => (
          <div className="flex">
            <span>Requests</span>
            <Input.Search
              onSearch={(e) => updateFilter("keyword", e)}
              placeholder="Search"
            />
          </div>
        )}
        dataSource={list}
        columns={columns}
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          showTotal:()=>{
            return `Total ${total} items `
          },
          current: filters.page,
          pageSize: filters.limit,
          onChange: (page, pageSize) => {
            setFilters({
              ...filters,
              page,
              limit: pageSize,
            });
          },
        }}
        sticky
        showHeader={!!list?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 565 }}
      />
      {/* </div> */}
    </div>
  );
};
