//created by Peggy on 2021/5/4
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "antd";

const YoutubeInputContainer = styled.div`
  padding-bottom: 24px;
  img {
    width: 100%;
  }
  .field-form-items {
    width: 100%;
  }
`;

interface Props {
  value?: string;
  onChange?: (val?: string) => void;
  placeholder?: string;
  label: string;
}

const YoutubeInput = ({
  value = "",
  onChange = () => {},
  placeholder,
  label,
}: Props) => {
  const [val, setVal] = useState(value);
  useEffect(() => setVal(value), [value]);
  return (
    <YoutubeInputContainer className="YoutubeInput-container">
      <div className="field-form-items">
        <span>{label}</span>
        <Input
          size="large"
          placeholder={placeholder}
          value={val}
          onBlur={(e) => onChange(val)}
          onChange={(e) => {
            setVal(e.target.value);
          }}
        />
      </div>
      {val?.indexOf("youtu") > -1 ? (
        <img
          src={`//img.youtube.com/vi/${val
            ?.match(
              /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
            )
            ?.pop()}/0.jpg`}
          alt="youtube_img"
        />
      ) : val?.indexOf("vimeo") > -1 ? (
        <img
          src={`//vumbnail.com/${val
            ?.match(/vimeo\.com\/([\d]{8,})/)
            ?.pop()}.jpg`}
          alt="vimeo_img"
        />
      ) : (
        ""
      )}
    </YoutubeInputContainer>
  );
};

export default YoutubeInput;
